// booking details for guest section style
.booking__details__guest__wrapper {
  // padding: 1rem;
  // margin: 1rem;

  .guest__detail__card {
    padding: 1rem;
    background-color: $slider-header-bg;
    border-radius: $border-radius8px;
    border: 1px solid $moderate-blue;

    .guest__name__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .guest__name {
        @include main-text();
        font-weight: $font-weight600;
      }

      .booking__channel__icon {
        width: 50px;
        height: auto;

        .booking__source__image {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    .guest__booking__date {
      @include sub-text();
      font-weight: $font-weight500;
    }

    .guest__email__contact__details {
      @include center-div-flex();
      justify-content: flex-start;
      color: $moderate-blue;
      font-weight: $font-weight500;

      .guest__email__id {
        margin-left: 0.5rem;
      }
    }

    .checkin__checkout__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .checkinout__content {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        .check__inout__details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
          background-color: $white-color;
          border-radius: $border-radius8px;

          .check__inout__icon {
            padding: 0.5rem;

            i {
              font-size: 1.688rem;
            }
          }

          .check-in-color {
            color: $dark-black;
            // color: $lime-green;
          }

          .check-out-color {
            // color: $smooth-red;
            color: $dark-black;
          }

          .check__inout__date {
            padding: 0rem 0.5rem;

            .check-in-color {
              // color: $lime-green;
              color: $dark-black;
              font-weight: $font-weight600;
            }

            .check-out-color {
              // color: $smooth-red;
              color: $dark-black;
              font-weight: $font-weight600;
            }

            .check__inout__label {
              @include sub-text();
              color: $table-month-text;
              font-weight: $font-weight400;
            }
          }
        }

        .check-in-border {
          border: 1px solid $dark-black;
        }

        .check-out-border {
          border: 1px solid $dark-black;
        }
      }

      .number__of__nights__wrapper {
        display: inline-flex;
        align-items: center;
        color: $moderate-blue;

        .night__icon {
          margin-right: 0.5rem;
        }

        .night__number {
          font-size: 1.445rem;
        }
      }
    }
  }

  .booking__room__detail__wrapper {
    display: flex;
    align-items: center;
    border-radius: $border-radius8px;
    border: 1px solid $border-line;

    .booking__room__image {
      display: flex;
      flex-direction: column;
      width: 200px;
      height: 183px;

      img {
        display: flex;
        flex: 1 0 auto;
        min-width: 200px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius8px 0 0 $border-radius8px;
      }
    }

    .booking__room__details {
      width: calc(100% - 200px);
      padding: 0.5rem 1rem;

      .room__type__meal__plan {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .room__type__name {
          display: flex;
          align-items: center;
          @include normal-text();
          font-weight: $font-weight500;

          i {
            font-size: 1.2rem;
            color: $moderate-blue;
          }
        }

        .room__type__meal__plan {
          width: fit-content;
          color: $white-color;
          padding: 0.3rem 0.65rem;
          background-color: $moderate-blue;
          border-radius: $border-radius8px;
        }
      }

      .booking__room__type__rate,
      .booking__room__nights,
      .booking__room__type__total__amount {
        display: flex;
        align-items: center;
        @include normal-text();
        font-weight: $font-weight500;

        i {
          font-size: 1.2rem;
          color: $moderate-blue;
        }
      }

      .booking__room__type__total__amount {
        width: fit-content;
        color: $white-color;
        font-weight: $font-weight400;
        text-align: center;
        padding: 0.3rem 0.65rem;
        background-color: $moderate-blue;
        border-radius: $border-radius8px;
      }
    }
  }

  .latest__bill__wrapper {
    .latest__bill__tab {
      .nav-tabs {
        width: 100%;
        padding: 0.3rem;
        border-radius: $border-radius8px;
        background-color: $slider-header-bg;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-item {
          flex: 1;

          .nav-link {
            @include normal-text();
            color: $moderate-blue;
            font-weight: $font-weight600;
            text-align: center;
            padding: 0.33rem 0.75rem;
            margin: 0 0.2rem;
            border: 0;

            &:hover {
              border: 0;
            }

            &:active {
              border: 0;
            }
          }

          .nav-link.active {
            border: 0;
            background-color: $white-color;
            border-radius: $border-radius8px;
            box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
              0px 2px 4px rgba(15, 86, 179, 0.2);
          }
        }
      }
    }
  }

  .room__bill__details__wrapper {
    border-radius: $border-radius8px;
    border: 1px solid $border-line;
    padding: 1.12rem 1rem;

    .bill__generated__date {
      @include normal-text();
    }

    .room__type__details__rate__wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .room__type__name {
        width: calc(100% - 100px);
        @include sub-text();
        font-weight: $font-weight500;
      }

      .room__rate {
        width: 100px;
        @include sub-text();
        text-align: right;
      }
    }
  }

  .booking__btn__alignment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .modify__bookinhg__btn {
    @include primary-btn($white-color,
      $soft-orange,
      $dark-orange,
      $white-color );
  }

  .checkin__bookinhg__btn {
    @include primary-btn($white-color, $light-blue, $bright-blue, $white-color);
  }

  .cancel__bookinhg__btn {
    @include primary-btn($white-color, $smooth-red, $strong-red, $white-color);
  }

  .download__invoice__btn {
    @include primary-btn($lime-green, $white-color, $lime-green, $white-color);
    font-weight: $font-weight500;
    border: 1px solid $lime-green;
    padding: 0.54rem 1rem;
  }
}

.booking__room__type__rate {
  .adult__child__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .adult__wrapper,
    .child__wrrapper {
      @include sub-text();
      color: $white-color;
      padding: 0.256rem 1rem;
      background-color: $soft-orange;
      border-radius: $border-radius12px;
    }
  }
}

.booking__details__price__card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;

  .booking__detaila__price__input__wrapper {
    width: calc(100% / 2);

    .price__input__label {
      @extend .standard-page--label !optional;
    }

    .price__input {
      display: flex;
      align-items: center;
      width: 100%;

      .price__label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 44px;
        color: $white-color;
        background-color: $moderate-blue;
        border-radius: $border-radius8px 0 0 $border-radius8px;
      }

      .price__amount {
        @include normal-text();
        width: 100%;
        padding: 0.565rem 1rem;
        border: 1px solid $border-line;
        border-left: none;
        background-color: $location-marker-gray;
        border-radius: 0 $border-radius8px $border-radius8px 0;
      }
    }
  }
}