// style for data card
// dara card mixin
@mixin data__card__icon__wrapper($bgColor, $iconColor) {
    @include center-div-flex();
    width: 30px;
    height: 30px;
    background-color: $bgColor;
    border-radius: $border-radius50per;

    .data--card--icon {
        color: $iconColor;
        font-size: 80%;
    }
}

.data-card {
    min-width: 160px;
    width: 100%;
    padding: 0.6rem;
    border-radius: $border-radius8px;
    border: 1px solid $very-light-gray;

    .data__card__heading {
        @include sub-text();
        color: $bold-gray;
        padding-bottom: 0.2rem;
    }

    .data__card__value {
        @include main-text();
        font-weight: $font-weight700;
        padding-bottom: 0.2rem;
    }

    .data__card__footer {
        @include center-div-flex();
        justify-content: space-between;
        color: $bold-gray;
    }

    .data__card__footer__text {
        @include small-text();
    }

    .data__card__icon__wrapper {
        &.bookings {
            @include data__card__icon__wrapper($pale-blue, $moderate-blue);
        }
    }

    .data__card__icon__wrapper {
        &.revenue {
            @include data__card__icon__wrapper($light-green, $dark-green);
        }
    }

    .data__card__icon__wrapper {
        &.roomNights {
            @include data__card__icon__wrapper($pale-blue, $moderate-blue);
        }
    }

    .data__card__icon__wrapper {
        &.adr {
            @include data__card__icon__wrapper($light-green, $dark-green);
        }
    }

    .data__card__icon__wrapper {
        &.avgLos {
            @include data__card__icon__wrapper($pale-blue, $moderate-blue);
        }
    }

    .data__card__icon__wrapper {
        &.abd {
            @include data__card__icon__wrapper($pale-blue, $moderate-blue);
        }
    }
}