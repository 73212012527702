.colr-pickr-wrapper {
  border: 1px solid #c0c0c0;
  width: 21rem;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // flex-direction: row;
  border-radius: 0.5rem;
  .colr-picker-value {
    color: white;
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
  }
  .color-picker-box {
    width: calc(100% - 48px);
  }
  .colr-picker {
    width: 100%;
    height: 3rem;
    border: 1px solid red;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &::-webkit-color-swatch {
      border-radius: 0.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
    }
    &::-moz-color-swatch {
      border-radius: 0.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
    }
    .picker-icon {
      font-size: 2.5rem;
    }
  }
  .colr-picker-icon-wrapper {
    // position: absolute;
    background-color: #e5f1ff;
    // right: 0rem;
    height: 3rem;
    width: 3rem;
    border: 1px soild green;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .picker-icon {
      font-size: 1.5rem;
      cursor: pointer;
      color: #223189;
    }
  }
}
