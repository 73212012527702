// stay details screen

.stay__screen__header__action__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .stay__header__action__box {
    display: flex;
    align-items: center;
    margin-left: auto;

    .upgrade__room__wrapper {
      min-width: 190px;

      .primary-btn {
        width: 100%;
      }
    }

    .early__room__wrapper {
      display: flex;
      gap: 1rem;

      .primary-btn {
        @include primary-btn(
          $strong-red,
          $white-color,
          $strong-red,
          $white-color
        );
        font-weight: $font-weight500;
        border: 1px solid $strong-red;
        padding: 0.5rem 1rem;
      }
      .back__btn_stay_details {
        @include normal-text();
        @include primary-btn(
          $moderate-blue,
          $white-color,
          $white-color,
          $moderate-blue
        );
        border: 1px solid $moderate-blue;
      }
    }
  }
}

.room__stay__guest__details__wrapper {
  display: flex;
  align-items: flex-start;

  .room__stay__guest__details__left__box {
    width: 45.6333%;
    flex-basis: 45.6333%;
    flex-grow: 0;
    max-width: 45.6333%;

    .room__stay__checkin__checkout {
      .check__in__out__date {
        padding: 1rem;
        background-color: $slider-header-bg;
        border-radius: $border-radius8px;
        border: 1px solid $moderate-blue;

        .checkin__checkout__wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .checkinout__content {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .check__inout__details {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 0.5rem;
              background-color: $white-color;
              border-radius: $border-radius8px;

              .check__inout__icon {
                padding: 0.5rem;

                i {
                  font-size: 1.688rem;
                }
              }

              .check-in-color {
                // color: $lime-green;
                color: $dark-black;
              }

              .check-out-color {
                // color: $smooth-red;
                color: $dark-black;
              }

              .check__inout__date {
                padding: 0rem 0.5rem;

                .check-in-color {
                  // color: $lime-green;
                  color: $dark-black;
                  font-weight: $font-weight600;
                }

                .check-out-color {
                  // color: $smooth-red;
                  color: $dark-black;
                  font-weight: $font-weight600;
                }

                .check__inout__label {
                  @include sub-text();
                  color: $table-month-text;
                  font-weight: $font-weight400;
                }
              }
            }

            .check-in-border {
              border: 1px solid $dark-black;
            }

            .check-out-border {
              border: 1px solid $dark-black;
            }
          }

          .number__of__nights__wrapper {
            display: inline-flex;
            align-items: center;
            color: $moderate-blue;

            .night__icon {
              margin-right: 0.5rem;
            }

            .night__number {
              font-size: 1.445rem;
            }
          }
        }
      }
    }

    .guest__details__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navigation__arrow {
        display: flex;
        align-items: center;

        .left__arrow,
        .right__arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: $soft-gray;
          border-radius: $border-radius50per;
          cursor: pointer;

          i {
            font-weight: $font-weight600;
          }

          &.active__nav {
            background-color: $very-soft-blue;
          }
        }
      }
    }

    .guest__details__card {
      padding: 1rem;
      background-color: $slider-header-bg;
      border: 1px solid $moderate-blue;
      border-radius: $border-radius8px;

      .guest__name {
        @include main-text();
        font-weight: $font-weight600;
      }

      .guest__email__contact__details,
      .guest__gender__age__wrapper {
        @include center-div-flex();
        justify-content: flex-start;
        color: $moderate-blue;
        font-weight: $font-weight500;

        .guest__email__id,
        .age {
          margin-left: 0.5rem;
        }
      }

      .guest__document__and__image__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .upload__document,
        .upload__image {
          @include center-div-flex();
          @include normal-text();
          width: 100%;
          cursor: pointer;
          font-weight: $font-weight500;
          color: $moderate-blue;
          padding: 0.5rem 1rem;
          background-color: $white-color;
          border: 1px solid $moderate-blue;
          border-radius: $border-radius8px;
        }
      }
    }

    .allocated__guest__room__wrapper {
      border: 1px solid $border-line;
      border-radius: $border-radius8px;

      .guest__room__image {
        position: relative;
        max-width: 100%;
        height: 200px;
        border-radius: $border-radius8px $border-radius8px 0 0 !important;

        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius8px $border-radius8px 0 0 !important;
        }

        .room__number {
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: $white-color;
          font-weight: $font-weight600;
          padding: 0.5rem 1rem;
          background-color: $lime-green;
          border-radius: $border-radius8px;
        }
      }

      .room__detail__wrapper {
        padding: 1rem;
        background-color: $white-color;
        border-radius: 0 0 $border-radius8px $border-radius8px;

        .room__name__with__meal__plan {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .room__name {
            @include normal-text();
            font-weight: $font-weight600;
          }

          .mela__plan__name {
            display: flex;
            align-items: center;
            width: fit-content;
            color: $white-text;
            padding: 0.34rem;
            background-color: $moderate-blue;
            border-radius: $border-radius8px;
          }
        }

        .room__price {
          @include normal-text();
          font-weight: $font-weight600;
        }
      }
    }

    .guest__price__breakup {
      padding: 1rem;
      background-color: $white-color;
      border: 1px solid $border-line;
      border-radius: $border-radius8px;

      .price__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price,
        .price__bold__text {
          @include normal-text();
        }
      }
    }
  }

  .room__stay__guest__details__right__box {
    width: 54.3667%;
    flex-basis: 54.3667%;
    flex-grow: 0;
    max-width: 54.3667%;

    .guest__room__billing__details {
      padding: 1rem;
      border: 1px solid $border-line;
      background-color: $white-color;
      border-radius: $border-radius8px;

      .guest__billing__header__action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bill__print__btn__wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .share__bill__wrapper {
            display: flex;

            .primary-btn {
              @include primary-btn(
                $lime-green,
                $white-color,
                $lime-green,
                $white-color
              );
              font-weight: $font-weight500;
              border: 1px solid $lime-green;
              padding: 0.54rem 1rem;
            }
          }
        }
      }

      .bill__due__collection__wrapper {
        .latest__bill__tab {
          .nav-tabs {
            width: 100%;
            padding: 0.3rem;
            border-radius: $border-radius8px;
            background-color: $slider-header-bg;
            box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
              inset 0px 2px 4px rgba(97, 97, 97, 0.2);

            .nav-item {
              flex: 1;

              .nav-link {
                @include normal-text();
                color: $moderate-blue;
                font-weight: $font-weight600;
                text-align: center;
                padding: 0.33rem 0.75rem;
                margin: 0 0.2rem;
                border: 0;

                &:hover {
                  border: 0;
                }

                &:active {
                  border: 0;
                }
              }

              .nav-link.active {
                border: 0;
                background-color: $white-color;
                border-radius: $border-radius8px;
                box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                  0px 2px 4px rgba(15, 86, 179, 0.2);
              }
            }
          }
        }

        .bill__price__breakup__wrapper {
          .bill__price__breakup {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .bill__price__details {
              width: 70%;

              .bill__price__date {
                @include normal-text();
              }

              .bill__room__type__details {
                @include normal-text();
                font-weight: $font-weight500;
              }
            }

            .bill__price {
              width: 30%;
              text-align: right;
              @include normal-text();
            }
          }
        }
      }
      .stay_details_balance {
        @include normal-text();
        display: flex;
        justify-content: space-between;
        align-items: center;
        .negative {
          @include normal-text();
          color: $strong-red;
        }
        .neutral {
          @include normal-text();
          color: $strong-lime-green;
        }
        .positive {
          @include normal-text();
          color: $moderate-blue;
        }
      }
    }
  }
}

.guest__change__room__request__wrapper {
  .select__roon__floor__wrapper {
    padding: 1rem;
    margin: 1rem;
    margin-top: 0rem;

    .floor__wise__room__select {
      .floor__number {
        width: fit-content;
        font-weight: $font-weight500;
        color: $dark-green;
        padding: 0.3rem 1rem;
        border-radius: $border-radius8px;
        background-color: $light-green;
      }

      .floor__number__wrapper {
        display: flex;
        flex-wrap: wrap;

        .floor__number__box {
          width: 100px;
          display: flex;
          align-items: center;
          text-align: center;
          border-radius: $border-radius12px;

          .react-ripples {
            border-radius: $border-radius8px;

            .floor__number {
              width: 96px;
              height: 96px;
              padding: 2.2rem 0;
              color: $white-text;
              font-weight: $font-weight700;
              text-align: center;
              border-radius: $border-radius12px;
              background-color: $lime-green;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.guest__extend__stay__slider__wrapper {
  padding: 1rem;
  padding-top: 0rem;
  margin: 1rem;
  margin-top: 0rem;

  .check__in__out__date {
    padding: 1rem;
    background-color: $slider-header-bg;
    border-radius: $border-radius8px;
    border: 1px solid $moderate-blue;

    .checkin__checkout__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .checkinout__content {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        .check__inout__details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
          background-color: $white-color;
          border-radius: $border-radius8px;

          .check__inout__icon {
            padding: 0.5rem;

            i {
              font-size: 1.688rem;
            }
          }

          .check-in-color {
            // color: $lime-green;
            color: $dark-black;
          }

          .check-out-color {
            // color: $smooth-red;
            color: $dark-black;
          }

          .check__inout__date {
            padding: 0rem 0.5rem;

            .check-in-color {
              // color: $lime-green;
              color: $dark-black;
              font-weight: $font-weight600;
            }

            .check-out-color {
              // color: $smooth-red;
              color: $dark-black;
              font-weight: $font-weight600;
            }

            .check__inout__label {
              @include sub-text();
              color: $table-month-text;
              font-weight: $font-weight400;
            }
          }
        }

        .check-in-border {
          border: 1px solid $dark-black;
        }

        .check-out-border {
          border: 1px solid $dark-black;
        }
      }

      .number__of__nights__wrapper {
        display: inline-flex;
        align-items: center;
        color: $moderate-blue;

        .night__icon {
          margin-right: 0.5rem;
        }

        .night__number {
          font-size: 1.445rem;
        }
      }
    }
  }

  .choose__new__checkin__checkout__wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0.658rem;
    margin-bottom: 2.6rem;
    border: 2px solid $moderate-blue;
    border-radius: $border-radius8px;
    cursor: pointer;

    .date__range__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: $moderate-blue;
    }

    .date__range__label {
      @include normal-text();
      font-weight: $font-weight400;
    }
  }

  .guest__details__price__breakup__extend__stay {
    padding: 1rem;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;

    .extend__stay__price {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .extend__room__price {
        @include normal-text();
      }
    }
  }

  .including__tax__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.upgrade__room__slider__wrapper {
  padding: 1rem;
  padding-top: 0rem;
  margin: 1rem;
  margin-top: 0rem;

  .current__selcted__room__detail__wrapper {
    border: 1px solid $border-line;
    border-radius: $border-radius8px;

    .guest__room__image {
      position: relative;
      max-width: 100%;
      height: 200px;
      border-radius: $border-radius8px $border-radius8px 0 0 !important;

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius8px $border-radius8px 0 0 !important;
      }

      .room__number {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: $white-color;
        font-weight: $font-weight600;
        padding: 0.5rem 1rem;
        background-color: $lime-green;
        border-radius: $border-radius8px;
      }
    }

    .room__detail__wrapper {
      padding: 1rem;
      background-color: $white-color;
      border-radius: 0 0 $border-radius8px $border-radius8px;

      .room__name__with__meal__plan {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .room__name {
          @include normal-text();
          font-weight: $font-weight600;
        }

        .mela__plan__name {
          display: flex;
          align-items: center;
          width: fit-content;
          color: $white-text;
          padding: 0.34rem;
          background-color: $moderate-blue;
          border-radius: $border-radius8px;
        }
      }

      .room__price {
        @include normal-text();
        font-weight: $font-weight600;
      }
    }
  }

  .upgrade__room__floor__selection {
    .floor__wise__room__select {
      .floor__number {
        width: fit-content;
        font-weight: $font-weight500;
        color: $dark-green;
        padding: 0.3rem 1rem;
        border-radius: $border-radius8px;
        background-color: $light-green;
      }

      .floor__number__wrapper {
        display: flex;
        flex-wrap: wrap;

        .floor__number__box {
          width: 100px;
          display: flex;
          align-items: center;
          text-align: center;
          border-radius: $border-radius12px;

          .react-ripples {
            border-radius: $border-radius8px;

            .floor__number {
              width: 96px;
              height: 96px;
              padding: 2.2rem 0;
              color: $white-text;
              font-weight: $font-weight700;
              text-align: center;
              border-radius: $border-radius12px;

              &:hover {
                cursor: pointer;
              }

              &.active__floor__selected {
                background-color: $lime-green;
              }

              &.default__floor__state {
                background-color: $soft-orange;
              }
            }
          }
        }
      }
    }
  }

  .upgrade__room__rate__wrappers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;

    .upgrade__room__rate__label,
    .upgrade__room__rate {
      @include normal-text();
    }

    .upgrade__room__rate__label {
      font-weight: $font-weight500;
    }
  }
}

.guest__upgrade__meal__plan__slider {
  padding: 1rem;
  padding-top: 0rem;
  margin: 1rem;
  margin-top: 0rem;

  .guest__current__meal__plan {
    padding: 1rem;
    background-color: $slider-header-bg;
    border-radius: $border-radius8px;
    border: 1px solid $moderate-blue;
  }

  .upgrdae__meal__plan__bookind__details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meal__plan__cards {
      width: calc(100% / 3);
      padding: 1rem;
      background-color: $slider-header-bg;
      border-radius: $border-radius8px;
      border: 1px solid $border-line;

      .meal__plans__label {
        @include normal-text();
      }

      .meal__plan__status__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status__number {
          @include main-text();
        }

        .status__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          background-color: $light-green;
          border-radius: $border-radius50per;

          i {
            font-size: 1.34rem;
            color: $moderate-blue;
          }
        }
      }
    }
  }

  .room__type__meal__plan {
    display: flex;
    align-items: center;

    .room__type {
      @include normal-text();
      font-weight: $font-weight600;
      color: $moderate-blue;
    }

    .meal__plan {
      color: $white-color;
      padding: 0.35rem 0.45rem;
      background-color: $moderate-blue;
      border-radius: $border-radius8px;
    }
  }

  .upgrade__meal__plan__wrappers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;

    .upgrade__meal__plan__label,
    .upgrade__meal__plan {
      @include normal-text();
    }

    .upgrade__meal__plan__label {
      font-weight: $font-weight500;
    }
  }
}

.view__image__div,
.view__doc__div {
  display: flex;
  padding: 1rem;
  min-height: 400px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: $border-radius8px;
    background-image: url("https: //dummyimage.com/600x400/918491/161621&text=No+Image+Found");
  }
}
