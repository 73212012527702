// style for bookings page
.manage__bookings__action__right {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 1rem;

  .status__icon__wrapper {
    font-weight: $font-weight700;
  }

  .booking__color {
    color: $soft-orange;
  }

  .checkedin__color {
    color: $moderate-blue;
  }

  .checkedout__color {
    color: $dark-bright-gray;
  }

  .left__arrow {
    display: inline-block;
    width: 25px;
    text-align: center;
    color: $moderate-blue;

    &:hover {
      cursor: pointer;
    }
  }

  .right__arrow {
    display: inline-block;
    width: 25px;
    text-align: center;
    color: $moderate-blue;
  }

  .calendar__view__wrapper {
    .calendar__view__tab {
      .nav-tabs {
        width: fit-content;
        padding: 0.3rem;
        border-radius: $border-radius8px;
        background-color: $slider-header-bg;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          @include normal-text();
          min-width: 40px;
          color: $moderate-blue;
          font-weight: $font-weight600;
          text-align: center;
          padding: 0.33rem 0.75rem;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: $white-color;
          border-radius: $border-radius8px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }
}

.calendar__month__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: $border-radius8px;
  background-color: $white-color;

  .calendar__year__wrapper {
    display: flex;
    align-items: center;
    min-width: 152px;
    max-width: 152px;

    .left__arrow {
      display: inline-block;
      width: 25px;
      text-align: center;
      color: $moderate-blue;

      &:hover {
        cursor: pointer;
      }
    }

    .year__label {
      display: inline-block;
      font-size: $font-size90;
      font-weight: $font-weight700;
      color: $moderate-blue;
      padding: 0.2rem 0.4rem;
      background-color: $pale-blue;
      border-radius: $border-radius5px;
    }

    .right__arrow {
      display: inline-block;
      width: 25px;
      text-align: center;
      color: $moderate-blue;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .calendar__month__list {
    width: calc(100% - 152px);
    max-width: 100%;

    .month__wrapper {
      display: flex;
      align-items: center;

      .month__item {
        @include sub-text();
        width: calc(100% / 12);
        text-transform: uppercase;
        text-align: center;
        padding: 0.5rem 0rem;
        cursor: pointer;
      }

      .active--month {
        color: $white-color;
        font-weight: $font-weight400;
        border-radius: $border-radius5px;
        background-color: $moderate-blue;
      }
    }
  }
}

.calendar__filter__wrapper {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  .search__booking__wrapper {
    display: flex;
    align-items: center;
    max-width: 220px;
    padding: 0.6rem 0.5rem;
    margin-right: 1rem;
    border-radius: $border-radius8px;
    background-color: $white-color;
    border: 1px solid #e0e0e0;

    i {
      text-align: center;

      &::before {
        width: 30px;
      }
    }
  }

  .filter__dropdown__wrapper {
    width: 180px;
    max-width: 180px;

    .MuiFormControl-root {
      max-width: 180px;
    }
  }

  .search__right__wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;

    .change__calendar__filter {
      margin-left: 1rem;
      display: flex;
      align-items: center;

      .left__arrow {
        display: inline-block;
        width: 25px;
        text-align: center;
        color: $moderate-blue;

        &:hover {
          cursor: pointer;
        }
      }

      .text__label__bookings {
        display: inline-block;
        font-size: $font-size90;
        font-weight: $font-weight700;
        color: $dark-green;
        cursor: pointer;
        padding: 0.2rem 0.4rem;
        background-color: $light-green;
        border-radius: $border-radius5px;
      }

      .occupied {
        background-color: $moderate-blue;
      }

      .right__arrow {
        display: inline-block;
        width: 25px;
        text-align: center;
        color: $moderate-blue;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.calendar__card__wrapper {
  .calendar__main__content {
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: $border-radius8px;
    background-color: $white-color;
    box-sizing: border-box;

    .calendar__layout__wrapper {
      .calendar__header__panel {
        width: auto;
        height: 120px;

        .calendar__label__text {
          @include normal-text();
          color: $dark-black;
          padding: 1.5rem 1rem;
          text-transform: uppercase;
        }

        .month__year__label {
          width: 100%;
          font-weight: $font-weight500;
          padding: 0.5rem 1rem;
          max-height: 41px;
          border-bottom: 1px solid #dee2e6;
        }

        .calendar__grid__row {
          fill: $white-color;
        }

        .calendar__grid__row_inventory {
          fill: $light-green;
        }

        .display__blocked__inventory {
          fill: $pale-red !important;
        }

        .blocked__inventory__value {
          fill: $strong-red !important;
        }

        .active__day {
          fill: $moderate-blue !important;
        }

        .active__color {
          fill: $white-color !important;
        }

        .calendar__grid__text {
          width: 60px;
          text-anchor: middle;
          fill: $dark-black;
        }

        .calendar__day {
          font-size: $font-size90;
          font-weight: $font-weight600;
        }

        .availability__inventory {
          @include sub-text();
          fill: $dark-green;
          text-anchor: middle;
          width: 40px;
        }
      }

      .calendar__room__types__section {
        position: relative;

        .room__typename__bg {
          fill: $moderate-blue;
          font-weight: $font-weight600;
        }

        .room__type__bg {
          fill: $very-soft-blue;
        }

        .calendar__grid__row {
          fill: $white-color;
        }

        .calendar__grid__row_inventory {
          fill: $light-green;
        }

        .display__blocked__inventory {
          fill: $pale-red !important;
        }

        .blocked__inventory__value {
          fill: $strong-red !important;
        }

        .availability__inventory {
          @include sub-text();
          fill: $dark-green;
          text-anchor: middle;
          width: 40px;
        }

        .guest__name {
          fill: $white-color !important;
          font-weight: $font-weight500;
        }

        .rect__hover {
          rect {
            cursor: pointer !important;
          }
        }

        .room__status__details {
          @include sub-text();
          position: absolute;
          background-color: $white-color;
          border-radius: $border-radius6px;
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
          z-index: 100;

          ul {
            li {
              display: flex;
              align-items: center;
              font-weight: $font-weight500;
              padding: 0.5rem 1rem;

              &:hover {
                cursor: pointer;
                background-color: $manage-drop-down-hotel-name;
              }

              .color__status {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: $border-radius50per;
              }

              .vacant--clean {
                background-color: $lime-green;
              }

              .occupied {
                background-color: $moderate-blue;
              }

              .text__label__bookings {
                display: inline-block;
                font-size: $font-size90;
                font-weight: $font-weight700;
                color: $dark-green;
                cursor: pointer;
                padding: 0.2rem 0.4rem;
                background-color: $light-green;
                border-radius: $border-radius5px;
              }

              .vacant--dirty {
                background-color: $dark-bright-gray;
              }

              .out--of--service {
                background-color: $strong-red;
              }
            }
          }
        }
      }
    }
  }
}

.calendar__card__wrapper {
  .font-office-view-not-available {
    @include center-div-flex();
    @include normal-text();
    min-height: 400px;
    color: $soft-red;
  }

  .calendar__main__content {
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: $border-radius8px;
    background-color: $white-color;
    box-sizing: border-box;

    .calendar__layout__wrapper {
      .calendar__header__panel {
        position: sticky;
        top: 4.1rem;
        width: auto;
        height: 120px;
        background-color: $white-color;
        z-index: 200;

        .calendar__label__text {
          @include normal-text();
          color: $dark-black;
          padding: 1.5rem 1rem;
          text-transform: uppercase;
        }

        .month__year__label {
          width: 100%;
          font-weight: $font-weight500;
          padding: 0.5rem 1rem;
          max-height: 41px;
          border-bottom: 1px solid #dee2e6;
        }

        .calendar__grid__row {
          fill: $white-color;
        }

        .calendar__grid__row_inventory {
          fill: $light-green;
        }

        .display__blocked__inventory {
          fill: $pale-red !important;
        }

        .blocked__inventory__value {
          fill: $strong-red !important;
        }

        .active__day {
          fill: $moderate-blue !important;
        }

        .active__color {
          fill: $white-color !important;
        }

        .calendar__grid__text {
          width: 60px;
          text-anchor: middle;
          fill: $dark-black;
        }

        .calendar__day {
          font-size: $font-size90;
          font-weight: $font-weight600;
        }

        .availability__inventory {
          @include sub-text();
          fill: $dark-green;
          text-anchor: middle;
          width: 40px;
        }
      }

      .calendar__room__types__section {
        position: relative;

        .room__typename__bg {
          fill: $moderate-blue;
          font-weight: $font-weight600;
          cursor: pointer;
        }

        .room__type__bg {
          fill: $very-soft-blue;
          // width: 8rem;
          width: 6rem;
        }

        .room__type__bg__Front {
          width: 7.5rem;
        }

        .calendar__grid__row {
          fill: $white-color;
        }

        .calendar__grid__row_inventory {
          fill: $light-green;
        }

        .display__blocked__inventory {
          fill: $pale-red !important;
        }

        .blocked__inventory__value {
          fill: $strong-red !important;
        }

        .availability__inventory {
          @include sub-text();
          fill: $dark-green;
          text-anchor: middle;
          width: 40px;
        }

        .guest__name {
          fill: $white-color !important;
          font-weight: $font-weight500;
        }

        .rect__hover {
          rect {
            cursor: pointer !important;
          }
        }

        .room__status__details {
          @include sub-text();
          position: absolute;
          background-color: $white-color;
          border-radius: $border-radius6px;
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
          z-index: 100;

          ul {
            li {
              display: flex;
              align-items: center;
              font-weight: $font-weight500;
              padding: 0.5rem 1rem;

              &:hover {
                cursor: pointer;
                background-color: $manage-drop-down-hotel-name;
              }

              .color__status {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: $border-radius50per;
              }

              .vacant--clean {
                background-color: $lime-green;
              }

              .occupied {
                background-color: $moderate-blue;
              }

              .vacant--dirty {
                background-color: $dark-bright-gray;
              }

              .out--of--service {
                background-color: $strong-red;
              }
            }
          }
        }
      }
    }
  }
}

.booking__crs__material__input {
  .inventory__date__select__btn {
    .MuiFormControl-root {
      .MuiOutlinedInput-input {
        padding: 10.5px !important;
      }

      // .MuiInputBase-root {
      // }
      .MuiFormHelperText-sizeMedium {
        display: none
      }
    }
  }
}

.display__booking__id {
  @include sub-text();
  font-size: $font-size80;
  font-weight: $font-weight500;
  fill: $white-color !important;
}

// .room_values {
//   display: none;
// }

// .upperValues {
//   display: none;
// }

.room_dates_cell {
  transition: all 0.15s ease-in-out;
}

.room_dates_cell:hover {
  fill: $pale-blue;
}

.room_dates_cell_hover_deactivated {
  transition: all 0.15s ease-in-out;
}