// modify booking style sheet

.sliding-pane-modify-booking .slide-pane__content {
    padding: 0 !important;
}

.modify__booking__nav__tab {
    padding: 1rem;
    margin: 1rem;
    margin-top: 0rem;

    .modify-crs-booking-nav {
        // justify-content: space-between;
        border-bottom: none;
    }

    .nav-tabs {
        .nav-item {
            .tab--link--text {
                @include normal-text();
                min-width: 110px;
                font-weight: $font-weight500;

                &:hover {
                    border-color: $white-color $white-color $white-color;
                }
            }

            .tab--link--text.active {
                color: $moderate-blue;
                font-weight: $font-weight500;
                background-color: $white-color;
                border-color: $white-color $white-color $moderate-blue;
                border-bottom-width: 3px;
            }
        }
    }

    .modify__booking__content {
        .check__in__out__date {
            padding: 1rem;
            background-color: $slider-header-bg;
            border-radius: $border-radius8px;
            border: 1px solid $moderate-blue;

            .checkin__checkout__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .checkinout__content {
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;

                    .check__inout__details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 0.5rem;
                        background-color: $white-color;
                        border-radius: $border-radius8px;

                        .check__inout__icon {
                            padding: 0.5rem;

                            i {
                                font-size: 1.688rem;
                            }
                        }

                        .check-in-color {
                            // color: $lime-green;
                            color: $dark-black;
                        }

                        .check-out-color {
                            // color: $smooth-red;
                            color: $dark-black;
                        }

                        .check__inout__date {
                            padding: 0rem 0.5rem;

                            .check-in-color {
                                // color: $lime-green;
                                color: $dark-black;
                                font-weight: $font-weight600;
                            }

                            .check-out-color {
                                // color: $smooth-red;
                                color: $dark-black;
                                font-weight: $font-weight600;
                            }

                            .check__inout__label {
                                @include sub-text();
                                color: $table-month-text;
                                font-weight: $font-weight400;
                            }
                        }
                    }

                    .check-in-border {
                        border: 1px solid $dark-black;
                    }

                    .check-out-border {
                        border: 1px solid $dark-black;
                    }
                }

                .number__of__nights__wrapper {
                    display: inline-flex;
                    align-items: center;
                    color: $moderate-blue;

                    .night__icon {
                        margin-right: 0.5rem;
                    }

                    .night__number {
                        font-size: 1.445rem;
                    }
                }
            }
        }

        .choose__new__checkin__checkout__wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            width: 100%;
            padding: 0.658rem;
            margin-bottom: 2.6rem;
            border: 2px solid $moderate-blue;
            border-radius: $border-radius8px;
            cursor: pointer;

            .date__range__picker__icon {
                position: absolute;
                left: 0;
                margin-left: 0.658rem;
                color: $moderate-blue;
            }

            .date__range__label {
                @include normal-text();
                font-weight: $font-weight400;
            }
        }

        .modify__booking__rooms__wrapper {
            .select__input__label {
                display: flex;
                flex-wrap: wrap;

                .select__input__div__active,
                .select__input__div {
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                    border: 2px solid $border-line;
                    border-radius: 16px;
                    transition: all .15s ease-in-out;
                }

                .select__input__div__active {
                    border-color: $soft-blue;
                    color: $moderate-blue;
                    font-weight: $font-weight700;
                }
            }

            .modify__rooms__wrapper {
                margin-bottom: 1rem;
                padding: 1rem 1rem 0rem 1rem;
                border: 1px solid $border-line;
                border-radius: $border-radius8px;

                .room__type__wrapper {
                    display: flex;
                    margin-top: 0.5rem;

                    .modify__room__type {
                        pointer-events: none;
                        width: 30%;
                    }

                    .modify__rate__plan {
                        width: 30%;
                    }

                    .modify__selling__rate {
                        width: 30%;
                    }
                }
            }

            .including__tax__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .modify__guest__booking__data__wrapper {

            .business__booking__wrapper,
            .company__name__address {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .company__name__address {

                .company__name,
                .company__address {
                    width: 100%;
                }
            }
        }

        .modify__bookings__billing__wraopper {
            .select__due__date__wrapper {
                display: flex;
                justify-content: center;
                position: relative;
                width: 100%;
                padding: 0.658rem;
                margin-bottom: 2.6rem;
                border: 2px solid $moderate-blue;
                border-radius: $border-radius8px;
                cursor: pointer;

                .select__date__picker__icon {
                    position: absolute;
                    left: 0;
                    margin-left: 0.658rem;
                    color: $moderate-blue;
                }

                .select__date__label {
                    @include normal-text();
                    font-weight: $font-weight400;
                }
            }

            .item__price__quantity__wrapper,
            .discount__tax__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item__price,
                .item__quntity,
                .discount__tax,
                .tax__percentage {
                    width: 100%;
                }
            }
        }
    }

    .availability__status__wrapper {
        margin-top: 3rem;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.89rem 1rem;
        box-shadow: 0px 1px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1);
        border-radius: 8px;

        .dates__wrapper {
            padding: 0.89rem 1rem;

            .dates__value {
                font-weight: 500;
                font-size: 16px;
            }
        }

        .available__status__wrapper {
            background: #E1F8E2;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.7rem 1rem;
            min-width: 180px;

            .status__value {
                color: #27B92D;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
            }
        }

        .notavailable__status__wrapper {
            background: #FFC6C7;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.7rem 1rem;
            min-width: 180px;

            .status__value {
                color: #E64467;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
            }
        }
    }



    .room__type__card {
        background: #FFFFFF;
        box-shadow: 0px 1px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1);
        padding: 0.98rem 1rem;
        border-radius: 8px;

        .room__rate__plan__display__wrapper {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            .room__type__display {
                width: 60%;

                p {
                    display: none;
                }
            }

            .rate__plan__display {
                width: 40%;

                .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
                    min-height: 1.8375em;
                }
            }
        }

        // .selling__rate__display {
        //     p {
        //         display: none;
        //     }
        // }

        .tax__wrapper {
            display: flex;
            justify-content: space-between;

            .tax__label {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }
        }

    }

    .booker__details__label {
        color: #414141;
        font-weight: 700;
        font-size: 16px;
        margin: 1.5rem 0;
    }

    .user__search__wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .input__user__number {
            width: 88%;

            .MuiFormControl-root .MuiOutlinedInput-input {
                // padding: 10px 14px !important;
                padding: 14px !important;
            }

            p {
                display: none;
            }
        }

        .search__button {
            width: 12%;

            .primary-btn {
                min-width: 0 !important;
            }
        }
    }

    .name__wrapper {
        .MuiFormControl-root .MuiOutlinedInput-input {
            // padding: 10px 14px !important;
            padding: 14px !important;
        }

        p {
            display: none;
        }
    }

    .address__wrapper {
        .MuiFormControl-root .MuiOutlinedInput-input {
            // padding: 10px 14px !important;
            padding: 14px !important;
        }

        p {
            display: none;
        }
    }

    .user__number__email__wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .input__user__number {
            width: 50%;

            .MuiFormControl-root .MuiOutlinedInput-input {
                // padding: 10px 14px !important;
                padding: 14px !important;
            }

            p {
                display: none;
            }
        }

        .input__user__email {
            width: 50%;

            .MuiFormControl-root .MuiOutlinedInput-input {
                // padding: 10px 14px !important;
                padding: 14px !important;
            }

            p {
                display: none;
            }
        }
    }


    .company__name__address__wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .company__name__wrapper {
            width: 50%;

            .MuiFormControl-root .MuiOutlinedInput-input {
                // padding: 10px 14px !important;
                padding: 14px !important;
            }

            p {
                display: none;
            }
        }

        .company__address__wrapper {
            width: 50%;

            .MuiFormControl-root .MuiOutlinedInput-input {
                // padding: 10px 14px !important;
                padding: 14px !important;
            }

            p {
                display: none;
            }
        }
    }

    .gstin__wrapper {
        .MuiFormControl-root .MuiOutlinedInput-input {
            // padding: 10px 14px !important;
            padding: 14px !important;
        }

        p {
            display: none;
        }
    }


    .booking__type__guest__name__wrapper {
        .modify__booking__type__wrapper {
            .checkbox_container {
                display: flex;
                flex-direction: row;

                .form-check {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .form-check-label {
                        color: #414141;
                        font-family: $manrope-font;
                        font-weight: $font-weight400;
                        margin-left: 1rem;
                        margin-right: 2rem;
                    }

                    .form-check-input {
                        height: 1.375rem;
                        width: 1.375rem;
                        align-self: center;
                    }
                }
            }
        }

        .guest__name__wrapper {
            .guest__name__in__room__type__wrapper {
                background: #FFFFFF;
                border: 0.5px solid #ADADAD;
                border-radius: 8px;
                padding: 1rem;
            }

            .label__value {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #414141;
                letter-spacing: 0.0168em;
            }

            .room__guest__name__wrapper {
                .room__label {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    color: #888888;
                    letter-spacing: 0.0168em;
                }

                .input__type__wrapper {
                    .MuiFormControl-root .MuiOutlinedInput-input {
                        // padding: 10px 14px !important;
                        padding: 14px !important;
                    }

                    p {
                        display: none;
                    }
                }
            }


        }

    }

    .remarks__wrapper {
        .internal__and__guest__remarks__wrapper {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
        }

        .internal__remark__wrapper {
            width: 100%;
        }

        .guest__remarks__wrapper {
            width: 100%;
        }
    }




    .check_avilability_btn {
        .primary-btn {
            width: 100%;
            z-index: 1;
        }
    }

}

.cancel__booking__btn__wrapper {
    width: 100%;

    .primary-btn {
        @include primary-btn($white-color, $smooth-red, $strong-red, $white-color);
        width: 100%;

        .modify_cancel_booking_btn {
            cursor: not-allowed !important;
            background-color: $lighter-gray !important;
        }
    }
}