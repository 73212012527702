.manage__bookings__action__right {
    position: relative;

    .new_booking_sub_tab {
        position: absolute;
        top: 50px;
        right: 0.8rem;
        background-color: $white-color;
        box-shadow: 0px 4px 16px rgba(120, 120, 120, 0.25);
        border-radius: $border-radius8px;
        cursor: pointer;
        transition-delay: 1s;
        z-index: 1;

        .room_booking_sub_tab {
            @include sub-text();
            width: 210px;
            font-size: 1rem;
            font-weight: 500;
            align-items: center;
            padding: 1rem;
            // border-bottom: 1px solid $border-line;

            .bi-box {
                color: $moderate-blue;
                font-weight: $font-weight600;
            }
        }

        .package_booking_sub_tab {
            @include sub-text();
            width: 210px;
            font-size: 1rem;
            font-weight: 400;
            align-items: center;
            padding: 1rem;

            .bi-boxes {
                color: $moderate-blue;
                font-weight: $font-weight600;
            }
        }
    }
}

.new_package_bookings__wrapper {
    @extend .inventory__update__wrapper !optional;
    // @extend .inventory__update__wrapper;

    .date_nights_label_content_wrapper {
        display: flex;
        justify-content: space-between;
        // align-items: start;
        align-items: flex-start;
        margin-top: 0.5rem;

        .checkin_dates_wrapper {
            width: 100%;

            .check_in_out_label {
                color: $dark-gray;
                font-weight: $font-weight500;
                font-size: 16px;
                @include normal-text();
            }

            .bookings__select__date__wrapper {
                @extend .inventory__select__date__wrapper !optional;
                position: relative;
                min-width: 300px;
                margin-bottom: 0%;

                .date__picker__icon {
                    position: absolute;
                    left: 0;
                    margin-left: 0.658rem;
                    color: #223189;
                }

                .DateRangePicker_picker {
                    left: 0px !important;
                    z-index: 3 !important;
                }
            }

            .check_in_date_showcase {
                padding: 10px;
                border: 1px solid $border-line;
                border-radius: $border-radius8px;

                .bi-calendar2 {
                    color: $moderate-blue;
                    font-weight: 500;
                }
            }
        }

        .nights_wrapper {
            .nights_label {
                color: $dark-gray;
                font-weight: $font-weight500;
                font-size: 16px;
            }

            .nights__contain {
                padding: 0.6rem;
            }
        }

        .rooms_wrapper {
            .rooms_label {
                color: $dark-gray;
                font-weight: $font-weight500;
                font-size: 16px;
                text-align: left;
            }

            .increment__decrement__btn_bookings {
                @extend .increment__decrement__btn !optional;
                height: 49px;
            }
        }
    }

    .dates__rooms__nights__title__container {
        display: flex;
        justify-content: space-between;
        color: $dark-gray;
        font-weight: $font-weight500;
        font-size: 16px;
        margin-top: 1rem;

        .check_in_out_label {
            width: 50%;
        }

        .nights_label_wrapper {
            display: flex;
            width: 50%;
            justify-content: space-around;
            // .nights_label {
            //   //  margin-left: 6rem !important;
            // }
        }
    }

    .new__room__type__progress__step {
        @extend .new__room__type__progress__stepper !optional;
        padding: 0rem;
        margin: 0rem;
    }

    .package_details_wrapper {
        .package_details_label {
            @include normal-text();
        }

        .package_details_card {
            display: flex;
            flex-direction: column;
            border-radius: $border-radius12px;
            border: 1px solid $border-line;
            padding: 15px;

            .package_name_nigth_price_wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .package_name {
                    @include normal-text();
                }

                .package_night_price {
                    @include normal-text();
                }

            }

            .package_room_duration_wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;

                .package_room_name {
                    @include normal-text();
                    font-weight: 500;

                    .bi-layout-sidebar-inset {
                        color: $moderate-blue;
                    }
                }

                .package_duration {
                    padding: 5px;
                    background-color: $very-pale-blue;
                    color: $moderate-blue;
                    border-radius: 22px;
                }
            }
        }
    }

    .package_room_wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid $border-line;
        border-radius: 8px;

        .package_room_name {
            @include normal-text();
        }

        .package_guest_details_wrapper {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: space-between;
            align-items: center;

            .package_adult_child_wrapper {
                width: 50%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
            }
        }
    }

    .rate_discount_wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .package_rate_breakdown_div {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $border-line;
        border-radius: $border-radius8px;
        background-color: $very-soft-blue;
        @include sub-text();

        .package_rate_title {
            padding: 3px;
            background-color: $white-color;
            border-radius: $border-radius4px;
            color: $moderate-blue;
        }
    }

    .dates__nights__rooms__content__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bookings__select__date__wrapper {
            @extend .inventory__select__date__wrapper !optional;
            width: 50%;
            margin-bottom: 0%;

            // .date__picker__icon {
            //   position: absolute;
            //   left: 0;
            //   margin-left: 0.658rem;
            //   color: #223189;
            // }
            .DateRangePicker_picker {
                left: 5px !important;
                z-index: 3 !important;
            }
        }

        .nights_rooms_container {
            width: 50%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .nights__container {
                // margin-left: 13rem;
                display: flex;
            }

            .increment__decrement__btn_bookings {
                @extend .increment__decrement__btn !optional;
                height: 48px;
                margin-top: 1rem;
            }
        }
    }

    .check_avilability_btn {
        margin-top: 1.3rem;
        font-weight: $font-weight700;
        font-size: 16px;

        .primary-btn {
            width: 100%;
        }
    }

    .roomtypes_header {
        display: flex;
        background-color: #e5f1ff;
        padding: 0.58rem 1rem;
        text-align: center;
        color: $dark-gray;
        font-weight: $font-weight700;
        border-radius: $border-radius8px;
        margin-top: 1rem;

        .room_type_header {
            margin-left: 10px;
        }

        .rate_room_container {
            @include center-div-flex();
            justify-content: space-between;
            margin-left: auto;

            .rate_room_label {
                padding-right: 3rem;
            }
        }
    }

    .roomtypes__content__container {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .roomtypes__display {
            color: $moderate-blue;
            display: flex;
            border-radius: $border-radius8px;
            position: relative;
            padding: 0.58rem 1rem;
            margin-top: 1rem;
            width: 55%;
            border: 1px solid $lighter-gray;

            .availability__content {
                position: absolute;
                right: 0;
                top: 0;
                color: $white-text;
                background-color: $moderate-blue;
                height: 100%;
                width: 20%;
                margin-left: 10px;
                border-bottom-right-radius: $border-radius8px;
                border-top-right-radius: $border-radius8px;

                .avl_label {
                    // margin-left: 1.4rem;
                    text-align: center;
                    margin: auto;
                    width: 50%;
                    font-size: 15px;
                    // margin-top: 0.1rem;
                }

                .inv_label {
                    text-align: center;
                    margin: auto;
                    width: 50%;
                    font-size: 15px;
                }
            }
        }

        .plan__dropdown__wrapper {
            gap: 1rem;
            width: 45%;
            display: flex;
            justify-content: space-between;
            // padding-right: 3rem;
            margin-top: 1rem !important;

            .plan_content {
                width: 60%;
            }
        }
    }

    .close_available_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .close_icon {
            margin-top: 7rem;
            color: $lighter-gray;
        }

        .rooms_available_label {
            color: $lighter-gray;
            margin-top: 0.3rem;
        }

        .available__rooms {
            color: $strong-blue;
            font-weight: $font-weight700;
            margin-top: 0.3rem;
        }
    }

    .left_right_btn_container {
        margin-top: 7rem;
        @include center-div-flex();
        justify-content: space-between;

        .alternatives_label {
            color: $dark-gray;
            font-weight: $font-weight700;
        }

        .btn_container {
            display: flex;
            gap: 1rem;

            .button-next {
                background-color: white;
                height: 2.5rem;
                width: 2.5rem;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #0f56b3;
                cursor: pointer;
                box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                    0px 2px 4px rgba(15, 86, 179, 0.2);
            }
        }
    }

    // .cards_wrapper {
    //   display: inline-flex;
    //   gap: 1rem;
    .availability_card_container {
        // margin-top: 1.5rem;
        padding: 0.5rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        height: 6rem;
        width: 30% !important;
        background-color: $white-color;
        border-radius: $border-radius8px;
        box-shadow: 0px 1px 16px 2px rgba(97, 97, 97, 0.1),
            0px 16px 32px 2px rgba(97, 97, 97, 0.1);

        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        .avl_rooms_label {
            margin-top: 0.5rem;
            background-color: $moderate-blue;
            color: $white-color;
            width: 60%;
            border-radius: $border-radius6px;
            padding-left: 5px;
            font-size: 12px;
        }

        .select_label {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            color: $moderate-blue;
            text-align: left;
            font-size: 12px;
        }

        .date_label {
            font-size: 12px;
        }
    }

    //  }
    .dates__nights__rooms__container {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .date_wrapper {
            display: flex;
            width: 50%;

            .calander__icon {
                color: #223189;
            }

            .confirm_date_label {
                margin-left: 0.6rem;
            }
        }

        // .nights_rooms_container {
        //   width: 50%;
        //   display: flex;
        //   justify-content: space-evenly;
        //   align-items: center;
        .room_nights_wrapper {
            display: flex;
            width: 50%;
            justify-content: space-around;

            .nights__container {
                //  margin-left: 13rem;
                display: flex;
            }

            .rooms__container {
                //  margin-left: 13rem;
                display: flex;
            }
        }

        // }
    }

    .bookingtype_label {
        color: $dark-gray;
        font-weight: $font-weight700;
        font-size: 16px;
        margin-top: 1.5rem;
    }

    .checkbox_container {
        display: flex;
        flex-direction: row;

        .form-check {
            display: flex;
            flex-direction: row;
            align-items: center;

            .form-check-label {
                color: #414141;
                font-family: $manrope-font;
                font-weight: $font-weight400;
                margin-left: 1rem;
                margin-right: 2rem;
            }

            .form-check-input {
                height: 1.375rem;
                width: 1.375rem;
                align-self: center;
            }
        }
    }

    .cart_card_wrapper {
        border-radius: $border-radius8px;
        background-color: $pale-blue;
        border: 1px solid $moderate-blue;
        margin-top: 1rem;

        .roomtype_wrapper {
            display: flex;
            margin: 1rem;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            // padding: 0.3rem;
            .roomtype__label {
                width: 308px;
                background-color: #eaeaea;
                border-radius: $border-radius8px;
                // margin: 1rem;
                padding: 0.5rem;
                color: $moderate-blue;
            }

            .plan_label {
                gap: 0.3rem;
                width: 100px;
                background-color: #eaeaea;
                border-radius: $border-radius8px;
                text-align: center;
                padding: 0.5rem;
                color: $moderate-blue;
            }
        }

        .roomrate_wrapper {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin: 0rem 1rem 1rem;

            .roomrate__label {
                width: 308px;
                border-radius: $border-radius8px;

                // padding-top: 0.5rem;
                color: $moderate-blue;

                .MuiOutlinedInput-input {
                    padding: 11px 14px !important;
                }

                .MuiFormHelperText-root {
                    display: none;
                }
            }

            .adult_dropdown {
                width: 100px;
                border-radius: $border-radius8px;
                // margin: 1rem;
                margin-left: 1rem;
                // padding-top: 0.5rem;
                color: $moderate-blue;
            }
        }

        .price_breakup_wrapper {
            display: flex;
            margin: 1rem;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            .plan_label {
                gap: 0.3rem;
                width: 100%;
                background-color: #eaeaea;
                border-radius: $border-radius8px;
                text-align: center;
                padding: 0.5rem;
                color: $moderate-blue;
            }
        }

        .toggle__wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            .tax_label {
                margin-left: 1rem;
            }

            .toggle-btn {
                margin-left: 3rem;
            }
        }
    }

    .guest__details__wrapper {
        border: 1px solid $border-line;
        border-radius: $border-radius8px;
        margin-top: 1.5rem;

        .guest_details_label {
            margin: 1rem;
        }

        .guest__input__container {
            display: flex;
            align-items: baseline;

            .guestname__label {
                width: 50%;
                // margin-left: 0.5rem;
                margin: 0.5rem;
                border-radius: $border-radius8px;

                padding: 0.5rem;
                color: $moderate-blue;

                .MuiOutlinedInput-input {
                    padding: 14px 14px !important;
                }
            }
        }

        .adult_dropdowns {
            width: 17%;
            border-radius: $border-radius8px;
            // margin: 1rem;
            margin-left: 1.1rem;
            padding: 0.5rem;
            color: $moderate-blue;
        }
    }

    .Guest_details_label {
        margin-top: 2rem;
    }

    .package_price_breakup_wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-top: 1rem;
        width: 100%;
        border: 1px solid $border-line;
        border-radius: $border-radius8px;
        padding: 15px;


        .price_break_up_wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;

            .price_break_up_heading {
                @include normal-text();
            }

            .price_break_up_summery {
                display: flex;
                flex-direction: row;

                .inr__label {
                    left: 0;
                    top: 0;
                    color: $white-text;
                    padding: 0.58rem 1rem;
                    background-color: $moderate-blue;
                    height: 100%;
                    width: 25.5%;
                    border: 1px solid $moderate-blue;
                    // margin-left: 10px;
                    border-bottom-left-radius: $border-radius8px;
                    border-top-left-radius: $border-radius8px;
                }

                .price__label {
                    width: 90%;
                    color: $dark-gray;
                    padding: 0.58rem 1rem;
                    border-bottom-right-radius: $border-radius8px;
                    border-top-right-radius: $border-radius8px;
                    border: 1px solid $lighter-gray;
                    background-color: $location-marker-gray;
                }
            }

        }


    }

    .bookingtype_label_wrapper {
        display: flex;

        .business_label {
            @extend .bookingtype_label;
            margin-left: 8rem;
        }
    }

    .bookingtype_dropdowns_wrapper {
        display: flex;
        margin-top: 1rem;

        .type_dropdown {
            @extend .plan_content;
            width: 30%;
        }

        .source_dropdown {
            @extend .plan_content;
            width: 35%;
            margin-left: 1.8rem;
        }

        .timetype_dropdown {
            @extend .plan_content;
            width: 20%;
        }

        .time_dropdown {
            @extend .plan_content;
            width: 15%;
            margin-left: 1.8rem;
        }

        .payment_type_dropdown {
            @extend .plan_content;
            width: 15%;
        }

        .payment_quantity_dropdown {
            @extend .plan_content;
            width: 20%;
            margin-left: 1.8rem;
        }
    }

    .search__wrapper {
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        right: 0;

        .mobile_number {
            @extend .roomrate__label;
            width: 479px;

            .MuiOutlinedInput-input {
                padding: 11px 14px !important;
            }
        }

        .search_btn {
            right: 0;

            // margin-left: 1rem;
            .primary-btn {
                padding: 11px 14px !important;
            }
        }
    }

    .number_address_wrapper {
        display: flex;
        margin-top: 1rem;

        .number_container {
            width: 48.5%;

            .MuiOutlinedInput-input {
                padding: 11px 14px !important;
            }
        }

        .address_container {
            margin-left: 3%;
            width: 48.5%;

            .MuiOutlinedInput-input {
                padding: 11px 14px !important;
            }
        }
    }

    .bussiness__label__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .bussiness_label {
            color: $dark-gray;
            font-weight: $font-weight700;
            font-size: 16px;
            margin-right: 5rem;
        }
    }

    .payment_link_label {
        color: $dark-gray;
        margin-top: 1.5rem;
    }

    .partial_payment_label {
        color: $dark-gray;
        margin-top: 1rem;
    }

    .remarks_label {
        color: $dark-gray;
        font-weight: $font-weight700;
        font-size: 16px;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    .button-next {
        // position: relative;
        background-color: white;
        height: 2.5rem;
        width: 2.5rem;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #0f56b3;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
    }

    .slider {
        margin-top: 1rem;
    }
}