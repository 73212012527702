// dashboard style sheet
// dashboard style sheet
.section__heading {
  a {
    cursor: default;
  }

  @include center-div-flex();
  justify-content: flex-start;

  .breadcrumbs__labels {
    @include normal-text();
    color: $moderate-blue;

    &:hover {
      cursor: pointer;
    }
  }

  .breadcrumbs__labels_second {
    @include normal-text();
  }

  .breadcrumbs__arrows {
    padding: 0 0.3rem;
  }
}

.page__title__wrapper {
  margin-bottom: $standard-margin-bottom;

  .page__title {
    @include main-text();
    margin-bottom: 0.3rem;
  }

  .page__subtitle {
    @include normal-text();
    font-weight: $font-weight400;
  }
}

.property__breadcrumbs {
  display: flex;
  align-items: center;

  .manage__property__action__right {
    display: flex;
    margin-left: auto;
    gap: 1.23rem;

    .back__btn {
      @include normal-text();
      background-color: $white-text;
      color: $moderate-blue;
      border: 1px solid $lighter-gray;

      &::before {
        background-color: $white-color;
        color: $moderate-blue;
      }
    }
  }
}

// Updated dashboard style code
.dashboard__main__content__wrapper {
  .dashboard__confirmed__bookings__wrapper {
    width: 100%;

    .dashboard__standard__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dashboard__standard__label {
        @include extra-bold();
      }

      .dashboard__standard__action__btns {
        .nav-tabs {
          width: 100%;
          padding: 0.3rem;
          border-radius: $border-radius8px;
          background-color: $slider-header-bg;
          box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
            inset 0px 2px 4px rgba(97, 97, 97, 0.2);

          .nav-item {
            flex: 1;

            .nav-link {
              @include normal-text();
              color: $moderate-blue;
              font-weight: $font-weight600;
              text-align: center;
              padding: 0.33rem 0.75rem;
              margin: 0 0.2rem;
              border: 0;

              &:hover {
                border: 0;
              }

              &:active {
                border: 0;
              }
            }

            .nav-link.active {
              border: 0;
              background-color: $white-color;
              border-radius: $border-radius8px;
              box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                0px 2px 4px rgba(15, 86, 179, 0.2);
            }
          }
        }
      }
    }

    .dashboard__confirm__cards {
      display: flex;
      align-items: center;

      .dashboard__card__box {
        width: calc(100% / 6);
        background-color: $white-color;
        border-radius: $border-radius8px;
      }
    }
  }

  display: flex;
  width: 100%;

  .dashboard__left__content {
    width: 66.3667%;
    flex-basis: 66.3667%;
    flex-grow: 0;
    max-width: 66.3667%;

    .dashboard__standard__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dashboard__standard__label {
        @include extra-bold();
      }

      .dashboard__standard__action__btns {
        .nav-tabs {
          width: 100%;
          padding: 0.3rem;
          border-radius: $border-radius8px;
          background-color: $slider-header-bg;
          box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
            inset 0px 2px 4px rgba(97, 97, 97, 0.2);

          .nav-item {
            flex: 1;

            .nav-link {
              @include normal-text();
              color: $moderate-blue;
              font-weight: $font-weight600;
              text-align: center;
              padding: 0.33rem 0.75rem;
              margin: 0 0.2rem;
              border: 0;

              &:hover {
                border: 0;
              }

              &:active {
                border: 0;
              }
            }

            .nav-link.active {
              border: 0;
              background-color: $white-color;
              border-radius: $border-radius8px;
              box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                0px 2px 4px rgba(15, 86, 179, 0.2);
            }
          }
        }
      }
    }

    .dashboard__rooms__cards {
      display: flex;
      align-items: center;

      .dashboard__card__box {
        width: calc(100% / 4);
        background-color: $white-color;
        border-radius: $border-radius8px;
      }
    }

    .dashborad__standard__chart__wrapper {
      width: 100%;
      padding: 1rem;
      z-index: 5;
      background-color: $white-color;
      border-radius: $border-radius;
      border: 1px solid $very-light-gray;
      position: relative;

      .pie-nodata-container {
        height: 405px;

        .no-data-label-container {
          @include center-div-flex();
          flex-direction: column;
          height: inherit;
          color: $lighter-gray;

          .icon-container {
            font-size: 25px;
          }
        }
      }

      .dashboard__pie__chart {
        display: flex;
        position: relative;
        width: 100%;

        // .pie__chart__wrapper-label{
        //   @include center-div-flex();
        //   position: relative;
        //   width: 50%;
        //   flex-basis: 100%;
        //   flex-grow: 0;
        //   max-width: 50%;

        // }

        .pie__chart__wrapper {
          @include center-div-flex();
          position: relative;
          width: 50%;
          flex-basis: 50%;
          flex-grow: 0;
          max-width: 50%;

          .center__data__inside__pie {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .pie__label {
              @include normal-text();
            }

            .pie__data {
              @include main-text();
            }
          }
        }
      }

      .dashboard__pie__legends {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;

        .ota__item__wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .ota__name__list {
            display: flex;
            align-items: center;
            min-width: 130px;
            width: fit-content;

            .ota__color__box {
              width: 20px;
              height: 20px;
              border-radius: $border-radius5px;

              &.ota--color--1 {
                background-color: $moderate-blue;
              }

              &.ota--color--2 {
                background-color: $strong-red;
              }

              &.ota--color--3 {
                background-color: $lime-green;
              }

              &.ota--color--4 {
                background-color: $dark-orange;
              }

              &.ota--color--5 {
                background-color: $popular-app-color-teal-blue;
              }

              &.ota--color--6 {
                background-color: $bright-yellow;
              }

              &.ota--color--7 {
                background-color: $very-light-blue;
              }

              &.ota--color--8 {
                background-color: $occupancy-changing;
              }
            }

            .ota__label {
              @include sub-text();
              font-size: $font-size90;
              font-weight: $font-weight400;
            }
          }
        }
      }

      .pie__chart__wrapper,
      .bar__chart__wrapper {
        width: 100%;
        height: 405px;
      }
    }
  }

  .dashboard__right__content {
    width: 33.6333%;
    flex-basis: 33.6333%;
    flex-grow: 0;
    max-width: 33.6333%;

    .dashboard__front__office__wrapper {
      .front__office__data__toggler {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dashboard__standard__label {
          @include extra-bold();
        }

        .dashboard__standard__action__btns {
          .nav-tabs {
            width: 100%;
            padding: 0.3rem;
            border-radius: $border-radius8px;
            background-color: $slider-header-bg;
            box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
              inset 0px 2px 4px rgba(97, 97, 97, 0.2);

            .nav-item {
              flex: 1;

              .nav-link {
                @include normal-text();
                color: $moderate-blue;
                font-weight: $font-weight600;
                text-align: center;
                padding: 0.33rem 0.75rem;
                margin: 0 0.2rem;
                border: 0;

                &:hover {
                  border: 0;
                }

                &:active {
                  border: 0;
                }
              }

              .nav-link.active {
                border: 0;
                background-color: $white-color;
                border-radius: $border-radius8px;
                box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                  0px 2px 4px rgba(15, 86, 179, 0.2);
              }
            }
          }
        }
      }
    }

    .dashboard__frontoffice__card {
      display: flex;
      align-items: center;

      .dashboard__frontoffice__box {
        width: calc(100% / 2);
        min-height: 113.11px;
        padding: 0.6rem;
        background-color: $white-color;
        border-radius: $border-radius8px;
        border: 1px solid $very-light-gray;

        .front__office__data {
          @include main-text();
          color: $moderate-blue;
          font-weight: $font-weight700;
        }

        .front__ofice__checkin__wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .front__ofice__label {
            @include sub-text();
            color: $moderate-blue;
            padding: 0.342rem 0.678rem;
            background-color: $pale-blue;
            border-radius: $border-radius12px;
          }

          .front__ofice__icon {
            i {
              font-size: 1.688rem;
            }

            &.checkin--color {
              color: $lime-green;
            }

            &.checkout--color {
              color: $smooth-red;
            }
          }
        }
      }
    }

    .dashboard__recent__activity__wrapper {
      .dashboard__standard__label {
        @include extra-bold();
      }

      .dashboard__recent__activity__log {
        .recent__log__wrapper {
          width: 100%;
          border-radius: $border-radius8px;
          // border: 1px solid $very-light-gray;
          min-height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 0;

          .someBox {
            width: 100%;
            min-height: 140px;
            margin-bottom: 15px;
            padding: 0.6rem;
            background-color: $white-color;
            border-radius: $border-radius8px;
            border: 1px solid $very-light-gray;
            box-sizing: border-box;
            display: flex;

            .recent__card {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .recent__log__dot {
                width: 10px;
                max-width: 20%;
                height: 10px;
                border-radius: $border-radius50per;

                &.warning__color {
                  background-color: $bright-yellow;
                }

                &.primary__color {
                  background-color: $soft-blue;
                }

                &.danger__color {
                  background-color: $soft-red;
                }

                &.success__color {
                  background-color: $lime-green;
                }

                &.orange__color {
                  background-color: $dark-orange;
                }
              }

              .recent__log__time {
                width: 25%;
                @include sub-text();
                font-weight: $font-weight400;
                color: $border-line;
                text-align: right;
              }

              .bi-info-circle {
                color: rgb(173, 173, 173);
                cursor: pointer;
              }
            }

            .left {
              width: 60%;
              box-sizing: border-box;
              // padding: 10px;

              .customerPhone {
                div {
                  strong {
                    color: $moderate-blue !important;
                  }
                }
              }
            }

            .right {
              width: 40%;
              box-sizing: border-box;
              // padding: 10px;
            }

            .left {
              @include sub-text();
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;

              .leftItems {
                width: 100%;
                display: flex;

                div {
                  margin-bottom: 0;
                }

                i {
                  margin-right: 10px;
                  color: $moderate-blue;
                }
              }

              .booking-date {
                display: flex;
                align-items: center;

                div {
                  color: $moderate-blue;
                }

                small {
                  font-size: 13px;
                  color: rgb(181, 181, 181);
                  margin-left: 1rem;
                }
              }

              .customerName {
                i {
                  font-size: 1rem;
                }
              }
            }

            .right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              @include sub-text();

              .channel_name {
                width: 50px;
                height: 50px;

                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                  padding: 0.25rem;
                }
              }

              .paid-amt {
                background-color: $pale-blue;
                min-width: 0px;
                padding: 0.5rem 1rem;
                border-radius: 8px;

                display: flex;
                justify-content: center;
                align-items: center;

                div {
                  margin-bottom: 0;
                  color: $moderate-blue;
                  font-weight: 600;
                }
              }
            }
          }

          .emptyBodyContainer {
            width: 100%;
            height: 140px;
            background-color: white;
            color: #a1a1a1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius8px;
            border: 1px solid $very-light-gray;
          }
        }
      }
    }

    .dashboard__learning__wrapper {
      width: 100%;
      padding: 1rem;
      background-color: $slider-header-bg;
      border-radius: $border-radius8px;
      border: 1px solid $popular-app-color-blue;

      .dashboard__learning__header {
        display: flex;
        align-items: center;

        .bookingjini__logo {
          width: 56px;
          height: 56px;
          background-color: $white-color;
          border-radius: $border-radius50per;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNyAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjQzODQgMTEuNDAwN0MyMC42MTkxIDYuOTU3MDkgMTMuNjc2OCA4LjE0NzcyIDEzLjY3NjggOC4xNDc3MkMxMC40MDYyIC0xLjQ0MTE2IDUuNTk1MTIgMC4wODk2NjMzIDUuNTk1MTIgMC4wODk2NjMzQzMuMjMxOCAwLjM2NjA2MSAxLjkwMjQzIDIuNzY4NiAxLjkwMjQzIDIuNzY4NkMtMi45OTMwMiAxMy44ODgzIDMuMTA1MTkgMjUuNTE4MyAzLjEwNTE5IDI1LjUxODNDMy41MDYxMSAyNi43MDg5IDMuOTkxNDQgMjcuODU3IDQuNTgyMjcgMjguOTQxM0M2LjgxODk5IDMzLjA0NDggMTAuMzAwNyAzMS44MzI5IDEwLjMwMDcgMzEuODMyOUMxNC42ODk3IDMxLjAyNSAxNC4wOTg5IDMwLjk2MTIgMTcuMTU4NSAzMC4wODk1QzE3LjM0ODQgMzAuMDI1NyAxOC44Njc3IDI5LjQwOTEgMTkuNTIxOCAyOS4xMzI3QzIxLjMxNTQgMjguMzY3MyAyMy4wMDM1IDI2Ljg1NzcgMjMuMDAzNSAyNi44NTc3QzMxLjA2NDEgMTguNTY1OCAyNC40Mzg0IDExLjQwMDcgMjQuNDM4NCAxMS40MDA3Wk0xNy43MjgzIDIxLjc3NjNDMTUuOTM0NyAyMS41NDI0IDE0LjY2ODYgMTkuODg0IDE0LjkwMDcgMTguMDU1NUMxNS4xMzI4IDE2LjI0ODMgMTYuNzc4NyAxNC45NzI2IDE4LjU5MzQgMTUuMjA2NUMyMC4zODcgMTUuNDQwNCAyMS42NTMxIDE3LjA5ODggMjEuNDIwOSAxOC45MjcyQzIxLjE2NzcgMjAuNzU1NyAxOS41MjE4IDIyLjAzMTQgMTcuNzI4MyAyMS43NzYzWiIgZmlsbD0iIzUyNjlDRSIvPgo8L3N2Zz4K");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
        }
      }

      .learning__heading__wrapper {
        .learning__main__heading {
          @include normal-text();
        }

        .learning__sub__heading {
          @include sub-text();
          font-weight: $font-weight400;
          color: $lighter-gray;
        }
      }

      .learning__text {
        @include sub-text();
        font-weight: $font-weight400;
      }

      .learning__image__bg {
        // max-width: 100%;
        width: 100%;
        // height: 230px;
        height: 250px;

        img {
          object-fit: contain;
          width: 100%;
          // max-width: 100%;
          height: 100%;
          border-radius: $border-radius8px;
        }
      }
    }
  }
}

// // Material Input overrides
.dashboard__frontoffice__data {
  .data__input__wrapper {
    .data__input {
      .MuiInputLabel-root {
        margin-top: -0.3875rem !important;
      }
    }
  }
}
