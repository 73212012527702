.partnerValidationTypeSelectionWrapper {
    width: 100%;
    // background-color: antiquewhite;
    // height: 250px;

    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;

    .validattionTypeLable {
        // background-color: aqua;
        display: flex;
        align-items: center;

        .lable,
        #yes {
            margin-right: 1rem;
        }

               ;
        label {
            margin-right: 0.5rem;
        }

        input:hover {
            -webkit-appearance: checkbox
        }

        input:active {
            -webkit-appearance: checkbox
        }

        input:focus {
            -webkit-appearance: checkbox
        }
    }

    .gstIdInput {
        // background-color: brown;
        width: 100%;
        height: 80%;
        margin-top: 2rem;


    }

    .agentOrCorporateRadioSelectorBox {
        div {
            input:hover {
                -webkit-appearance: radio
            }

            input:active {
                -webkit-appearance: radio
            }

            input:focus {
                -webkit-appearance: radio
            }
        }
    }
}
.arrayDataHolder {
    min-height: 0px;
    // margin-top: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-wrap: wrap;

    .contact {
      color: white;
      background-color: #223189;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      .bi-x-lg {
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }