.partner__rate__plan__box {
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    margin-bottom: 2rem;

    .partner__rate__plan__box__heading {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        background-color: $very-soft-blue;
        border-radius: 15px 15px 0px 0px;
        height: 55px;


        .leftHalf {
            height: 65%;
            width: 47%;
            display: flex;

            .rate__plan__heading {
                padding: 0 1rem;
                display: flex;
                background-color: $moderate-blue;
                color: white;
                margin-left: 1rem;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .apply__block {
            // background-color: aquamarine;
            height: 65%;
            width: 47%;
            // padding: 5px;
            margin-right: 8px;
            display: flex;
            // width: 38%;
            // justify-content: space-between;

            .inputBox {
                width: 45%;
                background-color: white;
                // width: 5rem;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 8px;
                border: 1px solid $moderate-blue;

                input {
                    width: 80%;
                }

                .percentBox {
                    width: 15%;
                    color: $moderate-blue;
                }
            }

            .apply__block__color {
                width: 45%;
                background-color: $moderate-blue;
                margin-left: 1rem;
            }
        }
    }

    .partner__rate__plan__box__content {
        display: flex;
        padding: 15px 5px 0px 5px;
        justify-content: space-between;

        .content__left {
            width: 100%;
            padding: 5px;
        }

        .content__right {
            width: 100%;
            height: auto;
            padding: 5px;

            .multiple__occupancy__container {
                height: 90%;

                .MuiInputBase-root {
                    height: 212px;
                    max-height: 212px;
                    display: flex;
                    flex-direction: column;

                    .rooms__input__container {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        width: 100%;
                        grid-gap: 1rem;

                        .room__number__div {
                            @include center-div-flex();
                            padding: 0.2rem 0.35rem;
                            margin: 0.25rem 0.5rem 0.25rem 0;
                            border-radius: $border-radius4px;
                            color: $white-color;
                            background-color: $primary-btn-color;

                            .icon--close {
                                font-size: 1.25rem;
                                cursor: pointer;
                            }
                        }
                    }
                }


            }
        }
    }
}