// style for standard prompt
.standard__confirmation__prompt__wrapper {
    position: fixed;
    box-sizing: border-box;
    inset: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.50);
    overflow: auto;
    z-index: 8000;

    /*highest z index on the project*/
    .prompt__wrapper {
        @include center-div-flex();

        .prompt__content__delete {
            width: 100%;
            max-width: 380px;
            position: relative;
            top: 3.5rem;
            padding: 1.5rem;
            background-color: $pale-red;
            border-radius: $border-radius12px;

            .prompt__text {
                @include center-div-flex();
                align-items: baseline;
                @include normal-text();
                font-weight: $font-weight500;
                color: $dark-black;

                .bi-question-circle {
                    position: relative;
                    top: 0.3rem;
                    font-size: 1.3rem;
                    color: $strong-red;
                }
            }

            .prompt__action {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .prompt__cancel {
                    max-width: 100px;
                    @include normal-text();
                    @include primary-btn($strong-red, $pale-red, $pale-red, $strong-red);
                    border: 1px solid $strong-red;
                }

                .prompt__delete {
                    max-width: 100px;
                    @include normal-text();
                    @include primary-btn($white-text, $strong-red, $occupancy-changing, $white-text);
                    border: 1px solid $strong-red;
                }
            }
        }

        .prompt__content__update {
            @extend .prompt__content__delete;
            background-color: $light-green;

            .prompt__text {
                .bi-check2 {
                    position: relative;
                    top: 0.2rem;
                    font-size: 1.5rem;
                    color: $dark-green;
                }
            }

            .prompt__action {
                .prompt__cancel {
                    @include primary-btn($dark-green, $light-green, $light-green, $dark-green);
                    border: 1px solid $dark-green;
                }

                .prompt__update {
                    @extend .prompt__delete;
                    @include primary-btn($white-text, $dark-green, $prompt-hover, $white-text);
                    border: 1px solid $dark-green;
                }
            }
        }
    }
}