// style for add new room type
.height-64px {
  height: 64px;
}

.side__slider__header__label__wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 8;
  background-color: $slider-header-bg;

  .side__slider__close {
    cursor: pointer;

    .bi-x {
      position: relative;
      top: 1px;
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }
  }

  .standard-page--label {
    color: $moderate-blue;
    font-weight: $font-weight700;
  }

  .side__slider__sub__heading {
    @include sub-text();
    color: $moderate-blue;
    font-weight: $font-weight600;
    margin-left: auto;
  }
}

.new__room__type__progress__stepper {
  @include center-div-flex();
  justify-content: space-between;
  // padding: 1rem;
  // margin: 0rem 1rem;
  // margin-top: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4zLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0Ni42IDUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ2LjYgNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0M2QzZDNjtzdHJva2Utd2lkdGg6MC4yNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9DQo8L3N0eWxlPg0KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjM3LjMiIHkxPSIyLjUiIHgyPSI0Ni42IiB5Mj0iMi41Ii8+DQo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMjUuNSIgeTE9IjIuNSIgeDI9IjM0LjgiIHkyPSIyLjUiLz4NCjxsaW5lIGNsYXNzPSJzdDAiIHgxPSIxMy44IiB5MT0iMi41IiB4Mj0iMjMuMSIgeTI9IjIuNSIvPg0KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjIiIHkxPSIyLjUiIHgyPSIxMS4zIiB5Mj0iMi41Ii8+DQo8L3N2Zz4NCg==");
  background-repeat: repeat-x;
  background-size: 100px 10px;
  background-position: 27% 27%;
  background-clip: content-box;

  .label__progress__wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;

    .progress__status {
      @include center-div-flex();
      width: 40px;
      height: 40px;
      border-radius: $border-radius50per;

      .bi-check2 {
        font-size: 1.8rem;
      }
    }

    .progress__status__label__text {
      @include normal-text();
      font-weight: $font-weight400;
      margin-top: 0.5rem;
    }

    .status--completed {
      color: $white-color;
      background-color: $lime-green;
      cursor: pointer;
    }

    .status--in--progress {
      cursor: pointer;
      color: $white-color;
      font-weight: $font-weight600;
      background-color: $moderate-blue;
    }

    .status--pending {
      color: $dark-gray;
      font-weight: $font-weight600;
      background-color: $soft-gray;
    }
  }
}

.property__setup__room__details {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem;

  .manage__add__roomtype__description__wrapper {
    border-radius: $border-radius8px;

    .description__label {
      @include normal-text();
      font-weight: $font-weight500;
      // margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .ck-editor__editable {
      min-height: 200px;
      max-height: 200px;
      background-color: $very-light-gray;
      border-radius: $border-radius8px;
      overflow-y: scroll;

      ul>li {
        margin: 0rem 1rem;
        padding: 0;
        text-indent: 0;
        list-style-type: disc;
      }
    }

    .ck-focused {
      border: 1px solid #c4c4c4 !important;
      box-shadow: none !important;
    }

    .character__count__number {
      border-top: 1px solid $border-line;

      .character__count__label {
        @include normal-text();
        color: rgba(129, 129, 129, 0.5);
        font-size: $font-size95;
        font-weight: $font-weight500;
        padding: 1rem 0rem;

        span {
          color: $moderate-blue;
        }
      }
    }
  }
}

.tab-content__add-room__details {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  padding: 1rem;
  padding-right: 2rem;
  gap: 1.7rem;

  .add-room-shortcode {
    width: 68%;
  }
}

.error__message__wrapper {
  margin-left: 1rem;
  padding-left: 1rem;
  margin-bottom: -0.25rem;
}

.property__setup__amenities__details {
  padding: 1rem;
  margin: 1rem;

  .accordion-item-margin-top {
    margin-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .amenities__list__wrapper {
    .accordion__custom__heading {
      @include extra-bold();
      font-weight: $font-weight600;

      &:not(.collapsed) {
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
      }

      .amenities__list__item__number {
        font-size: $font-size80;
        color: $dark-green;
        padding: 0.3rem 1rem;
        margin-left: 1rem;
        border-radius: $border-radius8px;
        background-color: $light-green;
      }
    }

    .accordion-button {
      &::after {
        order: -1; //swap order
        margin-left: 0;
        margin-right: 0.5rem; // just extra space between text and icon
      }
    }

    .amenities__categories__wrapper {
      padding: 0 1.8rem;

      .amenities__categories__list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amenities__name {
          @include normal-text();
          font-weight: $font-weight400;
        }

        .amenities__checkbox {
          .form-check {
            input {
              &[type="checkbox"] {
                width: 23px;
                height: 23px;
              }
            }
          }
        }
      }
    }
  }
}

.property__setup__room__images__wrapper {
  padding: 1rem;
  margin: 1rem;

  .property__setup__roomimages {
    .div_image_upload {
      border: 1px solid $border-line;
      box-shadow: unset;
    }
  }
}

.property__setup__room__type__ocuupancy {
  padding: 1rem;
  margin: 1rem;
}

.property__setup__room__types__rates {
  padding: 1rem;
  margin: 1rem;

  .room__rates__input__wrapper {
    width: 100%;

    .meal__plan__toogle__tab {
      .nav-tabs {
        width: fit-content;
        padding: 0.3rem;
        border-radius: $border-radius12px;
        background-color: $slider-header-bg;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          @include normal-text();
          min-width: 60px;
          color: $moderate-blue;
          font-weight: $font-weight600;
          text-align: center;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: $white-color;
          border-radius: $border-radius12px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }
}

.select_meal_label {
  @include normal-text();
  font-weight: $font-weight500;
}

.add__room__type__meal__plan__wrapper {
  padding: 0 1rem;
  margin: 0 1rem;

  .meal__plan__room__type {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .plan__view__wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .plan__view__tab {
      .nav-tabs {
        width: fit-content;
        padding: 0.3rem;
        border-radius: $border-radius8px;
        background-color: $slider-header-bg;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          @include normal-text();
          min-width: 40px;
          color: $moderate-blue;
          font-weight: $font-weight400;
          text-align: center;
          padding: 0.33rem 0.75rem;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: $white-color;
          border-radius: $border-radius8px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }

  .meal__plan__room__type_select {
    border: 1px solid black;
    margin-top: 1rem;
  }

  .others_plan_container {
    height: 203px;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;
    margin-top: 1rem;

    .plan_type_label {
      color: $dark-gray;
      font-weight: $font-weight400;
      margin: 1rem;
    }

    .all_plans_container {
      margin: 0.5rem;
    }

    .input_plan_name_container {
      margin: 1rem;
    }
  }

  .add_button_container {
    display: flex;
    justify-content: right;
    align-items: center;
    color: $moderate-blue;
    font-weight: $font-weight700;
    margin-top: 1rem;

    .add_new_button {
      margin-left: 1rem;
      cursor: pointer;
    }

    .plus_icon {
      font-weight: $font-weight700;
    }
  }

  .room__rates__edit__date__range__picker {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0.658rem;
    margin-bottom: 2.6rem;
    border: 2px solid $moderate-blue;
    border-radius: $border-radius8px;
    cursor: pointer;

    .date__range__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: $moderate-blue;
    }

    .date__range__label {
      @include normal-text();
      min-width: 300px;
      text-align: center;
      font-weight: $font-weight400;
      padding: 0rem 0.5rem;
      background-color: $white-color;
      z-index: 1;
    }
  }

  .edit__room__type__mt {
    margin-top: 2.3rem;
  }
}

.edit__room__type__nav__tabs {

  // padding: 0 1rem;
  // margin: 0 1rem;
  .edit__room__type__nav__wrapper {
    // padding-top: 1rem;
    margin-top: 1rem;
    height: "100%";
    width: "100%";

    .nav-tabs {
      .nav-item {
        .tab--link--text {
          @include normal-text();
          min-width: 110px;
          font-weight: $font-weight500;

          &:hover {
            border-color: $white-color $white-color $white-color;
          }
        }

        .tab--link--text.active {
          color: $moderate-blue;
          font-weight: $font-weight500;
          background-color: $white-color;
          border-color: $white-color $white-color $moderate-blue;
          border-bottom-width: 3px;
        }
      }
    }

    .tab-content__room__details {
      display: flex;
      align-items: center;
      //  padding-top: 1rem;
      //  margin-top: 1rem;

      .__default--input--field {
        flex-grow: 1;
        min-width: unset;

        &:last-child {
          margin-right: 0rem;
        }
      }

      .no_of_rooms_inputfield {
        width: 40%;
        margin-right: 2rem;
      }
    }

    .manage__roomtype__description__wrapper {
      // margin-top: 1rem;
      border-radius: $border-radius8px;

      .description__label {
        @include normal-text();
        font-weight: $font-weight500;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }

      .ck-editor__editable {
        min-height: 200px;
        max-height: 200px;
        background-color: $very-light-gray;
        border-radius: $border-radius8px;
        overflow-y: scroll;

        ul>li {
          margin: 0rem 1rem;
          padding: 0;
          text-indent: 0;
          list-style-type: disc;
        }
      }

      .ck-focused {
        border: 1px solid #c4c4c4 !important;
        box-shadow: none !important;
      }

      .character__count__number {
        border-top: 1px solid $border-line;

        .character__count__label {
          @include normal-text();
          color: rgba(129, 129, 129, 0.5);
          font-size: $font-size95;
          font-weight: $font-weight500;
          padding: 1rem 0rem;

          span {
            color: $moderate-blue;
          }
        }
      }
    }
  }

  // .tab-content_noofrooms {
  //   display: flex;
  //   align-items: center;

  //   .no_of_rooms_inputfield {
  //     width: 40%;
  //     margin-right: 2rem;
  //   }
  // }

  .remove__margin__padding {
    margin-top: 0;
    padding-top: 0;
  }
}

.edit__room__type__image__upload {
  // width: 100%;
  height: 80%;

  .image__upload__btn__wrapper {
    display: flex;
    align-items: center;
    justify-content: end;

    .image__upload__btn {
      max-width: 120px;
    }
  }

  .uploaded__image__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .image__upload_box {
      display: flex;
      flex-wrap: wrap;

      .uploaded__image__wrapper {
        flex: auto;
        min-width: 186px;
        max-width: 186px;
        padding: 0.6rem;
        margin-top: 2rem;
        margin-right: 1.03rem;
        border-radius: 8px;
        border: 1px solid silver;

        .uploaded__image {
          position: relative;
          width: 100%;
          height: 130px;
          object-fit: contain;
          border-radius: $border-radius8px;

          img {
            width: 100%;
            height: 100%;
            border-radius: $border-radius8px;
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            transition: background 0.5s ease;
            border-radius: $border-radius8px;

            .button__container {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: auto;

              .edit-button {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: opacity 0.35s ease;

                .bi-trash {
                  font-size: 1.3rem;
                  font-weight: $font-weight500;
                  color: $moderate-blue;
                }
              }
            }
          }

          &:hover {
            .overlay {
              display: block;
              background: rgba(0, 0, 0, 0.3);
              border-radius: $border-radius8px;

              &:hover {
                .button__container {
                  .edit-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 1;
                    width: 40px;
                    height: 40px;
                    opacity: 1;
                    text-align: center;
                    padding-top: 0.2rem;
                    color: $moderate-blue;
                    background-color: $white-color;
                    border-radius: $border-radius50per;
                    transition: opacity 0.35s ease;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .check__box__image {
          .form-check {
            input {
              &[type="checkbox"] {
                width: 23px;
                height: 23px;
              }
            }
          }
        }
      }
    }
  }
}

.delete__confirm__wrapper {
  width: 60%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.6rem;
  border-radius: $border-radius8px;
  background-color: $pale-red;

  .delete__confirm--text--label {
    @include normal-text();
    color: $strong-red;
    font-size: $font-size90;
    font-weight: $font-weight500;
  }

  .delete__confirm__no__yes {
    display: flex;
    align-items: center;

    .__no {
      cursor: pointer;
    }

    .delete__confirm__btn {
      color: $white-color;
      font-weight: $font-weight400;
      padding: 0.3rem 0.6rem;
      margin-left: 1rem;
      background-color: $strong-red;
      border-radius: $border-radius8px;
      box-shadow: 0px 1px 2px rgba(218, 16, 11, 0.2),
        0px 2px 4px rgba(218, 16, 11, 0.2);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.side__slider__panel__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem 1.5rem;
  // padding-top: 1.5rem;
  z-index: 10;
  background-color: $white-color;
  box-shadow: 0px -1px 16px 2px rgba(97, 97, 97, 0.1),
    0px -1px 32px 2px rgba(97, 97, 97, 0.03);

  .delete__btn__wrapper {
    color: $strong-red;
    font-weight: $font-weight600;
    background-color: $white-color;
    border: 1px solid $strong-red;

    &:hover {
      background-color: $light-pale-red;
    }

    &::before {
      background-color: $light-pale-red;
    }
  }
}

.div__new__roomtype__image__upload {
  @extend .div_image_upload;
}