// import google
@import "./fonts/_index.scss";

// import variables
@import "./variable/_index.scss";

// import helpers styles
@import "./helpers/_index.scss";

// import ui views styles
@import "./uielements/_index.scss";

// import component styles
@import "./components/_index.scss";

// import pages styles
@import "./pages/pages";

@import "./pages/season/index";

// import media query styles
// @import "./mediaquery/index";

@import "./pages/Partner/index";

@import "./pages/cancelation_policy/index.scss";


//settin all margin padding 0
* {
    margin: 0;
    padding: 0;
}

// .slide-pane__content {
//     max-height: 90%;
// }