.main__section__header__wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  .customer__search__wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    border-radius: $border-radius8px;
    background-color: $white-color;
    border: 1px solid #e0e0e0;

    i {
      text-align: center;

      &::before {
        width: 30px;
      }
    }
  }

  .primary__button__override {
    min-width: unset;
    padding: 0.4rem 0.5rem;
  }

  .calculated__width {
    width: calc(100% / 5);
  }
}



.list-view-table-wrapper {
  cursor: default;
  width: 100%;

  .bi-caret-down-fill,
  .bi-caret-up-fill {
    font-size: 14px;
  }

  .table {
    cursor: default;
    background-color: white;
    border-radius: $border-radius12px;

    .listview__table__heading {
      background-color: $bg-color;
      color: $lighter-gray;

      th {
        font-weight: $font-weight600 !important;

        .poniter {
          cursor: pointer;
        }
      }
    }
  }

  // .membersContainer {
  //   width: 100%;
  //   min-height: 0px;
  //   background-color: white;

  //   .mcHeader {
  //     display: flex;
  //     border: 1px solid black;
  //     background-color: $bg-color;
  //     color: $lighter-gray;

  //     .mem_name {
  //       background-color: aqua;
  //       width: 23%;
  //     }

  //     .mem_mail {
  //       background-color: rgb(50, 116, 116);
  //       width: 37%;
  //     }

  //     .mem_ph {
  //       background-color: rgb(33, 90, 183);
  //       width: 20%;
  //     }

  //     .mem_action {
  //       background-color: rgb(156, 121, 220);
  //       width: 20%;
  //     }
  //   }
  // }
}



.listview__table__data {
  cursor: default;
  background-color: $white-color;
  border-radius: $border-radius8px;

  td {
    vertical-align: middle;

    .customer-details {
      padding: 0.5rem 0;

      .edit__sales__executive {
        @include center-div-flex();
        width: 30px;
        height: 30px;
        background-color: $pale-blue;
        border-radius: $border-radius50per;

        i {
          font-size: 1.10rem;
        }

        &:hover {
          cursor: pointer;
        }
      }
      .Activestatus{
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: 0;
       background-color: #D4FFDB;
       width: fit-content;
       size: 600;
       font-weight: 600;
      padding-left:  23px;
      padding-right:  23px;
      padding-top: 6px;
      padding-bottom: 6px;
       border-radius: 20px;
       color: #178015;
       }
      .InActivestatus{
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: 0;
       background-color: #FFD7E0;
       width: fit-content;
       size: 600;
       font-weight: 600;
      padding-left:  12px;
      padding-right:  12px;
      padding-top: 6px;
      padding-bottom: 6px;
       border-radius: 20px;
       color: #E64467;
       }
    }

    .customer-name {
      cursor: pointer;
      font-weight: $font-weight500;

      &:hover {
        color: $moderate-blue;
        text-decoration: underline;
        font-weight: $font-weight500;
      }
    }

    .max-width {
      // max-width: fit-content;
      max-width: 100%;
      min-width: 300px;

      // display: flex;
      // justify-content: space-between;

      // position: relative;

      .bi-three-dots-vertical {
        font-size: 22px;
        color: $lighter-gray;
        cursor: pointer;
      }

      .otherpOtions {
        position: absolute;
        top: 2rem;
        right: 1rem;
        z-index: 5;

        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        width: 140px;
        height: 160px;
        border-radius: 0.5rem;

        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 0.5rem;

        // align-items: flex-end;
        div {
          // height: 33%;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .customer-details-button {
    padding: 0.5rem 0;
    border-radius: $border-radius8px;
    text-align: center;
    background-color: $very-soft-blue;
  }

  .customer-view-button {
    @extend .customer-details-button;
    color: $moderate-blue;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
  }

  .customer-delete-button {
    padding: 0.5rem;
    color: $strong-red;
    border-radius: $border-radius8px;
    text-align: center;
    background-color: $pale-red;
    cursor: pointer;
  }
}

.table-borderless> :not(caption)>*>* {
  border-bottom: 8px solid $bg-color;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.list--view--pagination {
  display: flex;
  padding-bottom: 2rem;
  // margin-top: 7rem;

  .pagination__left {
    display: flex;
    justify-content: flex-start;

    .pagination__dropdown {
      min-width: 70px;
      background-color: $white-color;
    }

    ul {
      display: flex;

      .showing__page__number {
        @include center-div-flex();
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin-left: 0.9rem;
      }
    }

    .active__page {
      @extend .showing__page__number;
      color: $white-color;
      padding: 0 12px;
      font-weight: $font-weight600;
      border-radius: $border-radius50per;
      background-color: $moderate-blue;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      padding: 14px 14px !important;
    }

    .css-hdw1oc {
      span {
        padding-left: 0px;
        padding-right: 0px;
      }

      legend {
        width: 0 !important;
      }
    }
  }

  .pagination__right {
    display: flex;
    margin-left: auto;

    .arrow__container {
      @include center-div-flex();
      width: 36px;
      height: 36px;
      padding: 6px 8px;
      color: $moderate-blue;
      background-color: $soft-gray;
      border-radius: $border-radius50per;
      cursor: pointer;

      i {
        -webkit-text-stroke: 0.5px;
      }
    }

    .font--weight {
      font-weight: $font-weight700;
    }
  }

  .MuiSelect-select {
    padding: 8px 14px !important;
  }
}

.room__type__name__details {
  @include center-div-flex();
  justify-content: space-between;
  padding: 1rem;

  .serviceable__label {
    @include normal-text();
    font-weight: $font-weight500;
  }

  .reset-font-size {
    @include sub-text();
    font-weight: $font-weight500;
    padding-bottom: 0.2rem;
  }

  .room--type-label {
    @include normal-text();
  }
}

.custom-switch {
  .form-check-input {
    width: 3em !important;
    cursor: pointer;
  }

  input[type="checkbox"] {
    height: 24px;
  }
}

//css for view member
.new__room__type__progress__stepper {
  @include center-div-flex();
  justify-content: space-between;
  padding: 1.4rem;
  margin: 0rem 1rem;
  // margin-top: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4zLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0Ni42IDUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ2LjYgNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0M2QzZDNjtzdHJva2Utd2lkdGg6MC4yNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9DQo8L3N0eWxlPg0KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjM3LjMiIHkxPSIyLjUiIHgyPSI0Ni42IiB5Mj0iMi41Ii8+DQo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMjUuNSIgeTE9IjIuNSIgeDI9IjM0LjgiIHkyPSIyLjUiLz4NCjxsaW5lIGNsYXNzPSJzdDAiIHgxPSIxMy44IiB5MT0iMi41IiB4Mj0iMjMuMSIgeTI9IjIuNSIvPg0KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjIiIHkxPSIyLjUiIHgyPSIxMS4zIiB5Mj0iMi41Ii8+DQo8L3N2Zz4NCg==");
  background-repeat: repeat-x;
  background-size: 100px 10px;
  background-position: 27% 35%;
  background-clip: content-box;

  .label__progress__wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;

    .progress__status {
      @include center-div-flex();
      width: 40px;
      height: 40px;
      border-radius: $border-radius50per;

      .bi-check2 {
        font-size: 1.8rem;
      }
    }

    .progress__status__label__text {
      @include normal-text();
      font-weight: $font-weight400;
      margin-top: 0.5rem;
    }

    .status--completed {
      color: $white-color;
      background-color: $lime-green;
      cursor: pointer;
    }

    .status--in--progress {
      color: $white-color;
      font-weight: $font-weight600;
      background-color: $moderate-blue;
    }

    .status--pending {
      color: $dark-gray;
      font-weight: $font-weight600;
      background-color: $soft-gray;
    }
  }
}

.manage__actions__wrapper {
  margin-right: 1rem;
  // background-color: beige;


  height: 50px;
  // margin-bottom: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  .bookingTypes {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      input:hover {
        -webkit-appearance: radio
      }

      input:active {
        -webkit-appearance: radio
      }

      input:focus {
        -webkit-appearance: radio
      }
    }

    .css-q8hpuo-MuiFormControl-root {
      display: -webkit-inline-box;
    }
  }

}

// ======================bulk Upload================

.add__package__upload__image {
  .add__package__upload__section {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius8px;
    border: 1px solid $border-line;
    min-height: 200px;

    .drag__package__image__box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 160px;
      height: 85px;
      border-radius: $border-radius8px;
      border: 1px solid $border-line;

      .drag__text__label {
        @include sub-text();
        width: 120px;
        font-weight: $font-weight400;
        color: $lighter-gray;
        text-align: center;
      }
    }
  }
}