.loginContainer {
    width: 100vw;
    height: 100vh;
    display: flex;

    .left {
        position: relative;
        width: 30%;
        height: 100%;
        background-color: #22328A;
        display: flex;
        align-items: center;
        position: relative;

        .loginBox {
            position: absolute;
            z-index: 2;
            background-color: white;
            width: 35vw;
            min-height: 340px;
            // width: 500px;
            // height: 320px;
            border-radius: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            position: absolute;
            left: 10%;

            box-sizing: border-box;
            padding: 2rem;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .loginBtn {
                height: 50px;
                background-color: #223189;
                color: white;
                border-radius: 0.5rem;
                font-size: medium;
                border: none;
                cursor: pointer;
            }

            .loginBoxHeader {
                font-weight: 800;
                font-size: 1.5rem;
                font: $manrope-font;
                margin-bottom: 1rem;
            }

            .div--email--input {
                width: 100%;
            }

            .div--password--input {
                width: 100%;
            }
        }
    }

    .right {
        width: 70%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .topLoginImage {
            width: 72%;
            height: 50%;
            display: flex;
            position: relative;
            // background-color: aquamarine;

            img {
                width: 98%;
                object-fit: contain;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .bottomLoginImage {
            width: 72%;
            height: 50%;
            display: flex;
            position: relative;
            // background-color: antiquewhite;

            img {
                width: 98%;
                object-fit: contain;
                position: absolute;
                right: 0;
                bottom: 0;
            }

            .overlayImg {
                width: 52%;
                position: absolute;
                right: 0;
                bottom: 37%;
            }
        }
    }
}