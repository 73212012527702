@import "../../variable/index";

.season-list-view-table-wrapper {
  width: 100%;
  height: 100px;

  .bi-caret-down-fill,
  .bi-caret-up-fill {
    font-size: 14px;
  }

  .table {
    cursor: default;
    background-color: white; 

    border-radius: $border-radius12px;

    .booking--loader {
      @include center-div-flex();
      min-height: 400px;
    }

    .listview__table__heading {
      background-color: $bg-color;
      color: $lighter-gray;

      th {
        font-weight: $font-weight600 !important;

        .poniter {
          cursor: pointer;
        }
      }
    }

    tbody {
      .season__listview__table__data {
        background-color: $white-color;
        border-top-left-radius: $border-radius8px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        td {
          vertical-align: middle;

          .season-details {
            padding: 0rem 0rem;

            .season-edit-button {
              text-align: center;
              background-color: #4AD933;
              border-radius: $border-radius8px;
              color: white;
              width: 30px;
              // padding: 0.5rem 0.5rem;
              cursor: pointer;

            }
            .Activestatus{
              display: flex;
              align-items: center;
              margin-top: auto;
              margin-bottom: 0;
             background-color: #D4FFDB;
             width: fit-content;
             size: 600;
             font-weight: 600;
            padding-left:  17px;
            padding-right:  17px;
            padding-top: 6px;
            padding-bottom: 6px;
             border-radius: 20px;
             color: #178015;
             }
            .InActivestatus{
              display: flex;
              align-items: center;
              margin-top: auto;
              margin-bottom: 0;
             background-color: #FFD7E0;
             width: fit-content;
             size: 600;
             font-weight: 600;
            padding-left:  12px;
            padding-right:  12px;
            padding-top: 6px;
            padding-bottom: 6px;
             border-radius: 20px;
             color: #E64467;
             }
            .custom-switch {
              .form-check-input {
                width: 3em !important;
              }

            
            }
          }

          .booking__id {
            color: $moderate-blue;
            font-weight: $font-weight500;
            text-decoration: underline;
            cursor: pointer;
          }

          .listview__image {
            width: 100px;
            height: 35px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .booking__date {
            padding: 0.5rem 1rem;
            font-weight: $font-weight500;
          }

          .guest {
            max-width: 200px;
            font-weight: $font-weight500;
          }

          .check__in__out {
            color: $moderate-blue;
            font-weight: $font-weight500;
          }

          .prepaid__label {
            max-width: 160px !important;
            padding: 0.3rem 1rem;
            color: $dark-green;
            text-align: center;
            font-weight: $font-weight500;
            border-radius: $border-radius12px;
            background-color: $light-green;
          }

          .confirmed__label {
            max-width: 190px !important;
            margin: 0 auto;
            margin-right: 0 !important;
            padding: 0.4rem 1rem;
            text-align: center;
            border-radius: $border-radius8px;
            background-color: $very-soft-blue;

            // &:hover {
            //   cursor: pointer;
            // }
          }

          .pay__at__hotel {
            max-width: 160px !important;
            padding: 0.3rem 1rem;
            color: $strong-red;
            text-align: center;
            font-weight: $font-weight500;
            border-radius: $border-radius12px;
            background-color: $pale-red;
          }

          .cancelled {
            max-width: 190px !important;
            margin-right: 0 !important;
            margin: 0 auto;
            padding: 0.4rem 1rem;
            color: $white-color;
            text-align: center;
            border-radius: $border-radius8px;
            background-color: $strong-red;

            // &:hover {
            //   cursor: pointer;
            // }
          }

          .partialy__paid {
            max-width: 160px !important;
            padding: 0.3rem 1rem;
            color: $soft-orange;
            text-align: center;
            font-weight: $font-weight500;
            border-radius: $border-radius12px;
            background-color: $booked_inventory;
          }
        }
      }

    }

  }



  .table-borderless> :not(caption)>*>* {
    border-bottom: 8px solid $bg-color;
  }

  .season-list--view--pagination {
    display: flex;
    padding-bottom: 2rem;

    .pagination__left {
      display: flex;
      justify-content: flex-start;

      .pagination__dropdown {
        min-width: 70px;
        background-color: $white-color;
      }

      ul {
        display: flex;

        .showing__page__number {
          @include center-div-flex();
          width: 36px;
          height: 36px;
          cursor: pointer;
          margin-left: 0.9rem;
        }
      }

      .active__page {
        @extend .showing__page__number;
        color: $white-color;
        padding: 0 12px;
        font-weight: $font-weight600;
        border-radius: $border-radius50per;
        background-color: $moderate-blue;
      }

      .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 14px 14px;
      }

      .css-1z7n62 {
        span {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    .pagination__right {
      display: flex;
      margin-left: auto;

      .arrow__container {
        @include center-div-flex();
        width: 36px;
        height: 36px;
        padding: 6px 8px;
        color: $moderate-blue;
        background-color: $soft-gray;
        border-radius: $border-radius50per;
        cursor: pointer;

        i {
          -webkit-text-stroke: 0.5px;
        }
      }

      .font--weight {
        font-weight: $font-weight700;
      }
    }

    .MuiSelect-select {
      padding: 8px 14px !important;
    }
  }

}

//Date picker override
.MuiOutlinedInput-notchedOutline {
  border-color: $moderate-blue !important;
  // new added can be modified
  // padding: 0px 140px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: inherit !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  background-color: $white-color !important;
}

.text_date_picker_wrapper {
  width: 100%;
  .date_nights_label_content_wrapper_season {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    width: 100%;

    .checkin_dates_wrapper {
      width: 100%;
      .check_in_out_label {
        color: $dark-gray;
        font-weight: $font-weight500;
        font-size: 16px;
      }

      .bookings__select__date__wrapper {
        @extend .inventory__select__date__wrapper !optional;
        position: relative;
        min-width: 576px;
        margin-bottom: 0%;

        .date__picker__icon {
          position: absolute;
          left: 0;
          margin-left: 0.658rem;
          color: #223189;
        }

        .DateRangePicker_picker {
          left: -195px !important;
          z-index: 3 !important;
        }
      }
    }
  }
}

.inventory__select__date__wrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  padding: 0.658rem;
  margin-bottom: 2.6rem;
  border: 2px solid $moderate-blue;
  border-radius: 8px;
  cursor: pointer;

  .date__picker__icon {
    position: absolute;
    left: 0;
    margin-left: 0.658rem;
    color: #223189;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }

  .date__label {
    @include normal-text();
    font-weight: $font-weight400;
    z-index: 1;
  }

  .DateRangePicker {
    .DateInput_input {
      &::-webkit-input-placeholder {
        color: transparent;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: transparent;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
}