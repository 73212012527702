// style for manage inventory section
.inventory__sticky__operations__btn {
  position: sticky;
  top: 65px;
  padding-top: 1rem;
  background-color: $bg-color;
  z-index: 2;
}

.manage__inventory__action__wrapper {
  display: flex;
  flex-direction: column;

  .inventory__breadcrumbs {
    display: flex;

    .manage__inventory__action__left {
      display: flex;
      gap: 1rem;
      margin-left: auto;

      .inventory__update__btn {
        .update__btn {
          width: fit-content;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }

      .inventory__sync__btn {
        .sync__btn {
          @include primary-btn($white-color,
            $lime-green,
            $dark-green,
            $white-color );
          width: fit-content;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }

      .inventory__block__btn {
        .block__btn {
          @include primary-btn($white-color,
            $smooth-red,
            $moderate-red,
            $white-color );
          width: fit-content;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }

      .inventory__unblock__btn {
        .unblock__btn {
          width: fit-content;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }

  .manage__inventory__action__right {
    display: flex;
    padding: 0.5rem 0;

    .inventory__apply__changes__btn {
      width: fit-content;
      box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
        0px 2px 4px rgba(15, 86, 179, 0.2);
    }

    .mui__inputs {
      display: flex;
      margin-left: auto;
      gap: 1rem;
      align-items: center;

      .inventory__date__select__btn {
        .date__select__btn {
          // @include primary-btn($moderate-blue,
          //   $white-color,
          //   $white-color,
          //   $moderate-blue );
          width: fit-content;
          font-weight: $font-weight600;
          border: 1px solid $border-line;
        }

        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
          background-color: $white-color !important;
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          height: 1em;
          padding: 14px 14px;
        }

        .css-1z7n62 {
          span {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }

      .inventory__room__type__select {
        .css-q8hpuo-MuiFormControl-root {
          min-width: 250px;
          background-color: $white-color;
          border-radius: $border-radius8px;

          .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            border-radius: $border-radius8px;

            // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            //   padding: 0px 140px;
            // }
          }
        }

        .css-1z7n62 {
          span {
            padding-left: 0px;
            padding-right: 0px;
          }
        }

      }
    }
  }
}

.inventory__border__right {
  border-right: 1px solid $table-border-color;
}

.inventory__border__bottom {
  border-bottom: 1px solid $table-border-color;
}

.manage__inventory__table__wrapper {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  box-sizing: border-box;

  .inventory__data__wrapper {
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: $border-radius8px;
    background-color: $white-color;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ineventory--loader {
      @include center-div-flex;
      min-height: 60vh;
    }

    .inventory__sticky {
      position: sticky;
      top: 222.53px;
      background-color: $white-color;
      z-index: 2;
      width: 100%
    }

    .inventory__data__row {
      display: flex;
      width: 100%;

      .inventory__lable__header {
        min-width: 180px;

        .inventory__label__text {
          @include normal-text();
          color: $dark-black;
          padding: 1.5rem 1rem;
        }

        .inventory__label__booked {
          @include normal-text();
          color: $table-month-text;
          padding: 1.5rem;
        }

        .inventory__total__number {
          @extend .inventory__label__text;
          width: fit-content;
          min-width: 42px;
          color: $dark-green;
          padding: 0.234rem;
          text-align: center;
          background-color: $light-green;
          border-radius: $border-radius12px;
          margin: 1rem;
        }

        .inventory__ota__image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 100%;
          padding: 1.5rem;

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }

      .inventory__label__dates {
        display: flex;
        align-items: center;
        width: calc(100% - 180px);

        .inventory__dates__wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
          width: 100%;
          height: 100%;
          text-align: center;

          .inventory__date__month {
            @include sub-text();
            color: $table-month-text;
            font-weight: $font-weight800;
            padding: 0 1rem;
          }

          .inventory__date__week {
            color: $table-month-text;
            font-size: $font-size90;
            font-weight: $font-weight300;
          }

          .inventory__number {
            @include normal-text();
            color: $table-month-text;
          }

          .soldout__text {
            color: $strong-red;
          }

          .inventory__ota__number {
            width: 100%;
            // padding: 0.5rem;
            cursor: pointer;

            .inventory__available {
              @include normal-text();
              // display: inline-flex;
              position: relative;
              // width: 100%;
              width: auto;
              color: $dark-green;
              padding: 0.5rem;
              // background-color: $light-green;
              background-color: $white-color;
              border-radius: $border-radius8px;
              border: 1px solid transparent;
              cursor: pointer;
              position: relative;
              margin-top: 0.5rem;
              margin-left: 0.5rem;
              margin-right: 0.5rem;

              .block_inventory {
                display: none;
                color: #ffffff;
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 100%;
                background-color: $strong-red;
              }

              &:hover {
                .block_inventory {
                  //   visibility: visible;
                  display: block;
                  height: 2.5rem;
                  color: #ffffff;
                  position: absolute;
                  padding-top: 0.5rem;
                  border-radius: 4px;
                  width: 30px;
                  height: 100%;
                  top: 0;
                  cursor: pointer;
                  right: 0;
                  opacity: 1;
                }
              }
            }

            .inventory__available__select {
              @include normal-text();
              // width: 100%;
              width: auto;
              color: $dark-green;
              padding: 0.5rem;
              background-color: $white-color;
              border-radius: $border-radius8px;
              border: 1px solid $dark-green;
              margin-top: 0.5rem;
              margin-left: 0.5rem;
              margin-right: 0.5rem;

              .block_inventory {
                display: none;
                color: #ffffff;
                position: absolute;
                right: 0%;
                top: 0;
                width: 30px;
                height: 100%;
                background-color: $strong-red;
              }
            }



            // ----------------
            .inventory__available__border {
              background-color: $white-color;
              border: 1px solid $dark-green;
            }

            .inventory__block__label {
              @extend .inventory__available;
              width: auto;
              position: relative;
              color: $strong-red;
              // margin-bottom: 10px;
              margin-top: 0.5rem;
              margin-left: 0.5rem;
              margin-right: 0.5rem;
              background-color: $pale-red;
              transition: all 0.245s ease-in-out;

              &:hover {
                cursor: pointer;
                color: $white-color;
                background-color: $moderate-blue;

                &::before {
                  content: "Unblock";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  @include center-div-flex();
                  font-weight: $font-weight400;
                  color: $white-color;
                  background-color: $moderate-blue;
                  border-radius: $border-radius8px;
                  z-index: 2;
                }
              }
            }

            .inventory__booked {
              @include normal-text();
              width: 100%;
              color: $soft-orange;
              // padding: 0.3rem 0.5rem;
              padding: 0.56323rem;
              // background-color: $booked_inventory;
              background-color: $white-color;
              border-radius: $border-radius8px;

              .bi-calendar-check {
                margin-right: 0.4rem;
              }
            }
          }
        }
      }
    }
  }
}

// Inventory Update Slider Model Box
.inventory__update__wrapper {
  // padding: 0 1rem 1rem 1rem;
  // margin: 1rem;

  .datepicker__wrapper {
    .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 14px 14px;
    }

    .css-1z7n62 {
      span {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .individual_dates {
    @include center-div-flex();
    display: inline-flex;
    padding: 0.1rem 0.35rem;
    margin: 0.25rem 0.5rem 0.25rem 0;
    border-radius: $border-radius4px;
    color: $white-color;
    background-color: $primary-btn-color;
    width: 8rem;

    .icon--close {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  .inventory__select__date__wrapper {
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 0.658rem;
    margin-bottom: 2.6rem;
    border: 2px solid $moderate-blue;
    border-radius: 8px;
    cursor: pointer;

    .date__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: #223189;
    }

    .DateRangePickerInput_arrow {
      display: none;
    }

    .date__label {
      @include normal-text();
      font-weight: $font-weight400;
      z-index: 1;
    }

    .DateRangePicker {
      .DateInput_input {
        &::-webkit-input-placeholder {
          color: transparent;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: transparent;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: transparent;
        }

        &:-ms-input-placeholder {
          color: transparent;
        }
      }
    }
  }

  // .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  //   padding: 14px 14px;
  // }
  // .css-1z7n62 {
  //   span {
  //     padding-left: 0px;
  //     padding-right: 0px;
  //   }
  // }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

  .inventory__sync__channel__wrapper {
    .select_all {
      display: flex;
      flex-direction: column;
    }

    .ota_error_message {
      @include small-text();
      background-color: #e64467;
      color: #ffffff;
      font-weight: $font-weight500;
      border: 1px solid #e64467;
      border-radius: 8px 8px 0 0;
      padding: 0.3rem 0.6rem;
    }

    .error--border {
      border-radius: 0px 0px 8px 8px !important;
      border: 1px solid #e64467 !important;
    }

    .success--border {
      border: 1px solid $strong-lime-green !important;
    }

    .channel__wrapper {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      padding: 0.89rem 1rem;
      border: 1px solid $border-line;
      border-radius: $border-radius8px;

      .channel__image__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .success__checkmark {
        @include center-div-flex();
        width: 20px;
        height: 20px;
        background-color: $strong-lime-green;
        border-radius: 50%;
        color: #ffffff;
        margin-left: 10px;
        left: 30px;
      }

      .channel__select__checkbox {
        .form-check {
          input {
            &[type="checkbox"] {
              width: 23px;
              height: 23px;
            }

            cursor: pointer;
          }
        }

        .input__position {
          margin-left: auto;
        }

        .custom-size[type="checkbox"] {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.success__checkmark {
  @include center-div-flex();
  width: 20px;
  height: 20px;
  background-color: $strong-lime-green;
  border-radius: 50%;
  color: #ffffff;
  margin-left: 10px;
  left: 30px;
}

.card__wrapper {
  display: inline-block;
  background-color: $white-color;
  border-radius: $border-radius12px;
  margin: 0.5rem;
  margin-left: 0rem;
  //min-width: 320px;
  width: 100%;
  //border: 1px solid $very-soft-blue;
  box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18),
    0px 4px 8px rgba(97, 97, 97, 0.18);

  .error_message {
    @include small-text();
    background-color: #e64467;
    color: #ffffff;
    font-weight: $font-weight500;
    border: 1px solid #e64467;
    border-radius: 8px 8px 0 0;
    padding: 0.3rem 0.6rem;
  }

  .error_border {
    border-radius: 0px 0px 8px 8px !important;
    border: 1px solid #e64467 !important;
  }

  .success_border {
    border: 1px solid $strong-lime-green !important;
    border-radius: 8px;
  }

  .card__wrapper__container {
    // position: relative;
    padding: 1rem;
    // display: flex;
    cursor: pointer;

    // flex-direction: column;
    .date__logo__container {
      display: flex;
      justify-content: space-between;

      padding-right: 1rem;

      .image__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100%;
        margin-bottom: 0.625rem;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .success_checkmark {
        @include center-div-flex();
        width: 20px;
        height: 20px;
        background-color: $strong-lime-green;
        border-radius: 50%;
        color: #ffffff;
        margin-left: 10px;
        left: 30px;
      }

      .updated__date {
        font-size: 14px;
        color: $table-month-text;
        font-weight: $font-weight700;
      }
    }

    .display__editprice {
      @include normal-text();
      width: 100%;
      color: #48834b;
      padding: 1rem 1rem;
      background-color: #e2edfc;
      border-radius: $border-radius6px;
      display: flex;
      font-size: 14px;
      flex-direction: row;

      .inventory {
        padding: 0.2rem 1.4rem;
        border-radius: $border-radius8px;
        background-color: #c5f2c7;
      }

      //   s {
      //     color:black;
      //     font-weight: 600;
      //     display: inline-block;
      //     text-decoration: none;
      //     position: relative;
      //   }
      // s:after {
      //     content: '';
      //     display: block;
      //     width: 100%;
      //     height: 50%;
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     border-bottom: 2px solid;
      // }

      .old__inventory {
        text-decoration: line-through;
      }
    }
  }
}

.cancel__btn {
  min-width: 106.33px;
  max-width: 106.33px;
  @include primary-btn($strong-red, $white-color, $pale-red, $strong-red);
  font-weight: $font-weight500;
  border: 1px solid $strong-red;

  &:hover {
    border-color: transparent;
  }
}

.select__all__checkbox__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .select__all__checkbox {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;

    .form-check-input {
      width: 23px;
      height: 23px;
    }

    input[type="checkbox"] {
      width: 23px;
      height: 23px;
    }
  }
}

.pro-sold {
  margin-bottom: 0rem !important;
}

.display-loader {
  display: flex;
  justify-content: center;

}

.blck-unblck-res {
  margin: 1rem 2rem 0rem 2rem;
  min-height: 8rem;
  width: 90%;
  border: 1px solid $border-line;
  background-color: $white-color;
  border-radius: $border-radius8px;

  .res-roomtype {
    height: 2rem;
    text-align: start;
    padding: 0rem;
    background-color: $slider-header-bg;
    color: $bright-blue;
    font-size: $font-size95;
    border-top-left-radius: $border-radius8px ;
    border-bottom-right-radius: $border-radius8px ;
    font-weight: $font-weight700;
    font-family: $manrope-font;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .res-card-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    --bs-gutter-x: 0;
    padding: 0.5rem;
    padding-left: 2rem;

    .channel-type-container {
      display: flex;
      min-height: 3.625rem;
      flex-direction: row;
      padding: 0.5rem;
      background-color: $white-color;
      box-shadow: $container-box-shadow;
      align-items: center;
      justify-content: space-between;
      border-radius: $border-radius12px;
      width: 45%;
      margin: 0.5rem;


      .res-channel {
        min-width: 5.313rem;
        height: 2rem;
      }

      .res-type {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        text-align: center;
      }

      .failed {
        background-color: #e64467;
      }

      .sucess {
        background-color: $lime-green;
      }

      .partial-sucess {
        background-color: $soft-orange;
      }
    }


  }
}


.sold__out__dates__wrapper {
  .soldout__dates {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .soldout__dates__list {
      @include sub-text();
      width: fit-content;
      color: $strong-red;
      padding: 0.123rem 0.568rem;
      margin-right: 0.665rem;
      font-weight: $font-weight500;
      background-color: $pale-red;
      border-radius: $border-radius6px;
    }
  }
}

// unset the legend width of mui feilds to view the top border
.mui__input__fieldset__legends__unset {
  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      fieldset {
        legend {
          width: unset;
        }
      }
    }
  }
}

.bi-check-lg {
  @include normal-text();
  color: $moderate-blue;
  padding: 0 0.5rem 0 0;
}

.min-width-126px {
  min-width: 126px;
}

.transactions__wrapper {
  .treansactions__card {
    .transactions-bold {
      @include normal-text();
    }

    .transaction__card__container {
      // @include center-div-flex();
      // justify-content: space-between;

      .transaction__details__wrapper {
        @include center-div-flex();
        align-items: start;
        justify-content: start;

        .transaction__date {
          @include normal-text();

          span {
            display: inline-block;
            width: 26px;
            height: 26px;
            text-align: center;
            color: $white-color;
            background-color: $moderate-blue;
            border-radius: $border-radius6px;
          }
        }

        .transaction__referance {
          @include normal-text();
          font-weight: $font-weight500;

          span {
            display: inline-block;
            width: 26px;
            height: 26px;
            text-align: center;
            color: $white-color;
            background-color: $moderate-blue;
            border-radius: $border-radius6px;
          }
        }
      }

      .transaction__amount__section {
        @include center-div-flex();
        justify-content: space-between;

        .transaction__amount {
          @include extra-bold();
        }

        .transaction__amount__edit__section {
          @include center-div-flex();

          .transaction__edit,
          .transaction__delete {
            @include center-div-flex();
            width: 30px;
            height: 30px;
            color: $white-color;
            border-radius: $border-radius50per;
            cursor: pointer;
          }

          .transaction__edit {
            background-color: $moderate-blue;
          }

          .transaction__delete {
            background-color: $strong-red;
          }
        }
      }
    }
  }
}

.total__amount__caontainer {
  color: $white-color;
  padding: 0.65rem 1rem;
  border-radius: $border-radius8px;
  background-color: $primary-btn-color;
}

// Material Input overrides
// .mui__inputs_inv {
//   .inventory__date__select__btn {
//     .MuiFormControl-root {

//       // .MuiOutlinedInput-input {
//       .MuiOutlinedInput-notchedOutline {
//         // padding: 10.5px 14px !important;
//         padding: 100%;
//       }
//     }
//   }
// }