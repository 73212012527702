// app content main wrapper styles

.content-wrapper {
  width: calc(100% - 250px);
  transition: margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: auto;

  .main__content__wrapper {
    margin: 0 1.375rem;
    // position: relative;
  }
}
.mange_user_wrapper_dropdown_logout {
  max-width: 100%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: $border-radius50per;
  transition: background-color 300ms;
  @include center-div-flex();
  background-color: $bg-color;

  &:hover {
    color: $moderate-blue;
    cursor: pointer;
    background-color: $very-soft-blue;

    .user__hotel__initial__name {
      color: $moderate-blue;
    }
  }

  .user__hotel__initial__name {
    @include normal-text();
    color: $moderate-blue;
    font-size: 1.568rem;
  }
}

.dropdown__logout__wrapper {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;

  .dropdown__menu_list {
    min-width: 260px;
    width: 260px;
    background-color: $white-color;
    color: $dark-black;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1),
      0px 16px 32px 2px rgba(97, 97, 97, 0.1);
    border-radius: $border-radius12px;
    overflow: hidden;
    border: none;
    transition: 0.3s ease-in all;

    .hotel__admin__user__name__logout {
      @include normal-text();
      font-weight: $font-weight400;
      color: $dark-black;
      padding: 0.4rem 0.8rem;
      margin: 0.5rem 1rem;
      border-radius: $border-radius8px;
      background-color: $manage-drop-down-hotel-name;
      transition: 0.1s ease-in all;

      &:hover {
        cursor: pointer;
        color: $moderate-blue;
        font-weight: $font-weight600;
      }
    }

    .hotel__admin__user__change__passowrd {
      @extend .hotel__admin__user__name__logout;
      background-color: transparent;
      transition: 0.1s ease-in all;

      &:hover {
        cursor: pointer;
        color: $moderate-blue;
        font-weight: $font-weight600;
      }
    }

    .hotel__admin__user__log__out__btn {
      @extend .hotel__admin__user__name__logout;
      color: $strong-red;
      background-color: transparent;
      transition: 0.1s ease-in all;

      &:hover {
        cursor: pointer;
        font-weight: $font-weight600;
        color: $strong-red;
      }
    }
  }

  &.dropDropMenuShow {
    max-height: 100%;
    opacity: 1;
    pointer-events: all;
    z-index: 10;
    transform: translate(-10px, 58px);
    // transition: transform 0.3s ease-in;
    transition: opacity 385ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.dropDropMenuHide {
    opacity: 0;
    pointer-events: none;
    transform: translate(-10px, 48px) scale(0);
    max-height: 0%;

    transition: opacity 385ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
.app__versioning__wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  padding: 0.65rem 1rem;
  margin-bottom: 0.65rem;
  background-color: rgba(255, 255, 255, 0.25);
  text-align: left;
  cursor: pointer;
  border-radius: $border-radius12px;

  .app__version {
    @include sub-text();
    font-weight: $font-weight600;
    color: $white-color;
  }
}
