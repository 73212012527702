// Style for room type occupancy.
.standard-page--label {
    @include normal-text();
    font-weight: $font-weight500;
}

.increment__decrement__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 135px;
    padding: 0.3rem;
    background-color: $white-color;
    border-radius: $border-radius8px;
    box-shadow: 0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2);
    .decrement__btn {
        width: 35px;
        height: 35px;
        @include primary-btn($occupancy-changing, $pale-red, $occupancy-changing, $white-color);
        @include center-div-flex();
        min-width: 35px;
        min-height: 35px;
        max-width: 35px;
        max-height: 35px;
        border-radius: $border-radius8px;
        background-color: $pale-red;
        .changing--icon {
            font-size: 2rem;
            font-weight: $font-weight600;
        }
    }
    .changing__value {
        min-width: 35px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: $font-weight700;
    }
    .increment__btn {
        @extend .decrement__btn;
        @include primary-btn($dark-green, $light-green, $dark-green, $white-color);
        @include center-div-flex();
        min-width: 35px;
        min-height: 35px;
        max-width: 35px;
        max-height: 35px;
        border-radius: $border-radius8px;
        background-color: $light-green;
    }
}

.max__occupancy__wrapper {
    @include center-div-flex();
    justify-content: space-between;
    flex-wrap: wrap;
    .guest__wrapper {
        min-width: 135px;
        margin-right: 1.975rem;
    }
}