.dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;


  .dashboard-left-sec {
    width: 70%;


    .dashborad__standard__chart__wrapper {
      width: 100%;
      min-height: 500px;
      padding: 1rem;
      z-index: 5;
      background-color: $white-color;
      border-radius: $border-radius;
      border: 1px solid $very-light-gray;
      position: relative;

      .pie-nodata-container {
        height: 405px;

        .no-data-label-container {
          @include center-div-flex();
          flex-direction: column;
          height: inherit;
          color: $lighter-gray;

          .icon-container {
            font-size: 25px;
          }
        }
      }

      .dashboard__pie__chart {
        display: flex;
        position: relative;
        width: 100%;

        // .pie__chart__wrapper-label{
        //   @include center-div-flex();
        //   position: relative;
        //   width: 50%;
        //   flex-basis: 100%;
        //   flex-grow: 0;
        //   max-width: 50%;

        // }

        .pie__chart__wrapper {
          @include center-div-flex();
          position: relative;
          width: 50%;
          flex-basis: 50%;
          flex-grow: 0;
          max-width: 50%;

          .center__data__inside__pie {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .pie__label {
              @include normal-text();
            }

            .pie__data {
              @include main-text();
            }
          }
        }
      }

      .dashboard__pie__legends {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;

        .ota__item__wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .ota__name__list {
            display: flex;
            align-items: center;
            min-width: 130px;
            width: fit-content;

            .ota__color__box {
              width: 20px;
              height: 20px;
              border-radius: $border-radius5px;

              &.ota--color--1 {
                background-color: $moderate-blue;
              }

              &.ota--color--2 {
                background-color: $strong-red;
              }

              &.ota--color--3 {
                background-color: $lime-green;
              }

              &.ota--color--4 {
                background-color: $dark-orange;
              }

              &.ota--color--5 {
                background-color: $popular-app-color-teal-blue;
              }

              &.ota--color--6 {
                background-color: $bright-yellow;
              }

              &.ota--color--7 {
                background-color: $very-light-blue;
              }

              &.ota--color--8 {
                background-color: $occupancy-changing;
              }
            }

            .ota__label {
              @include sub-text();
              font-size: $font-size90;
              font-weight: $font-weight400;
            }
          }
        }
      }

      .pie__chart__wrapper,
      .bar__chart__wrapper {
        width: 100%;
        height: 405px;


        .dashboard-tooltip-wrapper {
          display: flex;
          align-items: center;
          padding: 10px;
          background-color: $white-color;
          border-radius: $border-radius5px;


          .dashboard-tooltip-color {
            height: 15px;
            width: 15px;
            border-radius: $border-radius5px;
          }


          .dashboard-tooltip-label {
            @include normal-text();
            font-weight: 200;
          }


          .dashboard-tooltip-value {
            @include normal-text();
          }
        }
      }
    }

    .dashboard__standard__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dashboard__standard__label {
        @include extra-bold();
      }

      .dashboard__standard__action__btns {
        .nav-tabs {
          width: 100%;
          padding: 0.3rem;
          border-radius: $border-radius8px;
          background-color: $slider-header-bg;
          box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
            inset 0px 2px 4px rgba(97, 97, 97, 0.2);

          .nav-item {
            flex: 1;

            .nav-link {
              @include normal-text();
              color: $moderate-blue;
              font-weight: $font-weight600;
              text-align: center;
              padding: 0.33rem 0.75rem;
              margin: 0 0.2rem;
              border: 0;

              &:hover {
                border: 0;
              }

              &:active {
                border: 0;
              }
            }

            .nav-link.active {
              border: 0;
              background-color: $white-color;
              border-radius: $border-radius8px;
              box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                0px 2px 4px rgba(15, 86, 179, 0.2);
            }
          }
        }
      }
    }


    .dashboard__rooms__cards {
      display: flex;
      align-items: center;

      .dashboard__card__box {
        width: calc(100% / 4);
        background-color: $white-color;
        border-radius: $border-radius8px;
      }
    }

    .dashboard__confirm__cards {
      display: flex;
      align-items: center;

      .dashboard__card__box {
        width: calc(100% / 6);
        background-color: $white-color;
        border-radius: $border-radius8px;
      }
    }



  }


  .dashboard-right-sec {
    width: 30%;


    .agents_corporate_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-top: 4px;


      .agents_corporate_value_card {
        background-color: $white-color();
        border-radius: $border-radius8px;
        padding: 10px;
        width: 100%;
        border: 1px solid $border-line;


        .agents_corporate_label {
          @include normal-text();
        }


        .agents_corporate_value {
          font-weight: $font-weight600;
          font-size: 30px;
        }


        .agents_corporate_icon_wrapper {
          display: flex;
          justify-content: flex-end;

          .agents__icon {
            height: 30px;
            width: 30px;
            @include center-div-flex();
            border-radius: 50%;
            background-color: $very-soft-blue;


            .bi-file-earmark-person {
              color: $moderate-blue;
              font-size: 18px;
            }
          }


          .corporate_icon {
            height: 30px;
            width: 30px;
            @include center-div-flex();
            border-radius: 50%;
            background-color: $light-green;


            .bi-briefcase {
              color: $strong-lime-green;
              font-size: 18px;
            }
          }
        }
      }
    }


    .dashboard__rooms__cards {
      height: 400px;

      .dashboard__recent_bookings__card__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
        overflow: auto;

        .dashboard__recent_bookings__card {
          padding: 10px;
          border: 1px solid $border-line;
          border-radius: $border-radius10px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .recent_bookings__date__wrapper {
            @include center-div-flex();
            justify-content: space-between;


            .recent_bookings__booking_date {
              .bi-calendar-week {
                color: $moderate-blue;
                font-weight: bold;
                font-size: 17px;
              }


              @include sub-text();
              font-weight: 500;
            }


            .recent_bookings__today_wrapper {
              @include center-div-flex();


              .recent_bookings_today {
                @include sub-text();
                color: #c1c1c1;
              }


              .recent_bookings_today_date {
                @include normal-text();
                color: $moderate-blue;
              }
            }
          }


          .recent_bookings_agent_corporate_name {
            @include normal-text();
            font-weight: 500;
            color: $moderate-blue;

            .bi-file-earmark-person {
              font-size: 17px;
            }
          }


          .recent-bookings-name {
            .bi-person {
              font-size: 17px;
              color: $moderate-blue;
            }


            @include normal-text();
            font-weight: 500;
          }


          .recent-bookings-number-wrapper {
            @include center-div-flex();
            justify-content: space-between;


            .recent-bookings-number {
              @include normal-text();
              font-weight: 500;
              color: $moderate-blue;
            }


            .recent-bookings-price {
              padding: 5px;
              background-color: rgba(197, 220, 250, 0.5);
              @include normal-text();
              color: $moderate-blue;
              width: 80px;
              text-align: center;
              border-radius: $border-radius10px;
            }
          }
        }

        .no-data-label-container {
          @include center-div-flex();
          flex-direction: column;
          height: inherit;
          color: $lighter-gray;

          .icon-container {
            font-size: 25px;
          }
        }
      }
    }


    .dashboard__standard__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dashboard__standard__label {
        @include extra-bold();
      }

      .dashboard__standard__action__btns {
        .nav-tabs {
          width: 100%;
          padding: 0.3rem;
          border-radius: $border-radius8px;
          background-color: $slider-header-bg;
          box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
            inset 0px 2px 4px rgba(97, 97, 97, 0.2);

          .nav-item {
            flex: 1;

            .nav-link {
              @include normal-text();
              color: $moderate-blue;
              font-weight: $font-weight600;
              text-align: center;
              padding: 0.33rem 0.75rem;
              margin: 0 0.2rem;
              border: 0;

              &:hover {
                border: 0;
              }

              &:active {
                border: 0;
              }
            }

            .nav-link.active {
              border: 0;
              background-color: $white-color;
              border-radius: $border-radius8px;
              box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
                0px 2px 4px rgba(15, 86, 179, 0.2);
            }
          }
        }
      }
    }
  }
}

// Styles for Occupancy Calendar
.dashboard__standard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dashboard__standard__label {
    @include extra-bold();
  }

  .pagination__right {
    display: flex;
    margin-left: auto;

    .arrow__container {
      @include center-div-flex();
      width: 36px;
      height: 36px;
      padding: 6px 8px;
      color: $moderate-blue;
      background-color: $soft-gray;
      border-radius: $border-radius50per;
      cursor: pointer;

      i {
        -webkit-text-stroke: 0.5px;
      }
    }

    .font--weight {
      font-weight: $font-weight700;
    }
  }
}

.occupancy_calender_wrapper {
  width: 100%;
  background-color: $white-text;
  padding: 15px;
  border-radius: $border-radius;
  border: 1px solid $very-light-gray;
  display: flex;
  flex-flow: row wrap;

  // Css Code For Left Side Calendar
  .calender_left_month_wrapper {
    width: 48%;


    .calender_month_name {
      @include normal-text();
    }


    .calender_day_date_wrapper {
      display: flex;
      flex-direction: column;


      .calender_day_wrapper {
        @include center-div-flex();
        justify-content: space-around;


        .calendar_day_row {
          padding: 8px;
        }
      }


      .calender_date_wrapper {
        .date_wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;


          .calender_date_th {
            padding: 4px;
            width: 20%;
            height: 80px;
            font-weight: 500;

            .calender_date_div_wrapper {
              display: flex;
              flex-direction: column;
              padding: 5px;
              border: 1px solid $border-line;

              .day {
                font-size: 10px;
                font-weight: 500;

              }

              .available_room_price {

                text-align: center;

                .available_room {
                  font-size: 16px;
                  font-weight: 500;
                }

                .min_price {
                  font-size: 12px;
                  font-weight: 500;

                }


              }



              .today {
                background-color: #c1c1c1;
              }
            }

            .before {
              font-weight: 500;
              color: #e0e0e0;
              display: none;
              border: none;


              &:hover {
                cursor: no-drop;
              }
            }

            .beforeToday {
              display: none;
              border: none;
            }

            &:hover {
              cursor: pointer;
              color: $moderate-blue;
              font-weight: 900;
            }
          }

          .selected {
            // border: 1px solid $moderate-blue;
          }
        }
      }
    }
  }

  // Css Code For Middle Line
  .vertical_line {
    width: 1px;
    min-height: 250px;
    background-color: #c1c1c1;
    margin: 35px 10px 20px 10px;
  }

  // Css Code For Right Side Calendar
  .calender_right_month_wrapper {
    width: 48%;


    .calender_month_wrapper {
      @include center-div-flex();
      justify-content: space-between;


      .calender_month_name {
        @include normal-text();
      }


      .month_change_arrows {


        .bi-chevron-left,
        .bi-chevron-right {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }


    .calender_day_date_wrapper {
      display: flex;
      flex-direction: column;


      .calender_day_wrapper {
        @include center-div-flex();
        justify-content: space-around;


        .calendar_day_row {
          padding: 8px;
        }
      }


      .calender_date_wrapper {
        .date_wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;


          .calender_date_th {
            padding: 4px;
            width: 20%;
            height: 80px;

            .calender_date_div_wrapper {
              display: flex;
              flex-direction: column;
              padding: 5px;
              border: 1px solid $border-line;

              .day {
                font-size: 10px;
                font-weight: 500;
              }

              .available_room_price {
                text-align: center;

                .available_room {
                  font-size: 16px;
                  font-weight: 500;
                }

                .min_price {
                  font-size: 12px;
                  font-weight: 500;

                }
              }



              .today {
                background-color: #c1c1c1;
              }

              &:hover {
                cursor: pointer;
                color: $moderate-blue;
                font-weight: 900;
              }
            }

            .before {
              font-weight: 500;
              color: #e0e0e0;
              display: none;
              border: none !important;


              &:hover {
                cursor: no-drop;
              }

            }
          }


          .selected {
            // border: 1px solid $moderate-blue;
          }
        }
      }
    }
  }
}




// .calender_date_th {
//   width: 3rem;
// }

// .selected {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .occupancy_calender_wrapper {
//   margin-bottom: 2rem;
// }