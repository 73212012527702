// app header styles
.header-wrapper {
  width: 100vw;
  padding: 0.8rem 1rem;
  z-index: 1200;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  box-shadow: $header-box-shadow;

  .header__content__wrapper {
    @include center-div-flex();
    justify-content: flex-end;
    position: relative;

    .subscription__days__left {
      @include sub-text();
      color: $soft-red;
      font-weight: $font-weight500;
    }

    .profile-manage-wrapper {
      display: flex;
      align-items: center;

      .header__hotel__name__wrapper {
        @include primary-btn($primary-btn-color,
          $white-color,
          $moderate-blue,
          $white-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0rem 0.5rem;
        padding: 0.44rem 1rem;
        border: 1px solid $moderate-blue;
        border-radius: $border-radius8px;
        transition: background-color 300ms;

        .hotel__building__icon {
          color: $moderate-blue;
          margin-right: 0.5rem;
        }

        .hotel--name--header {
          color: $moderate-blue;
          font-size: 1rem;
          font-weight: 600;
        }

        &:hover {
          // cursor: pointer;
          // background-color: $moderate-blue;
          // background-color: $white-color;

          >.hotel--name--header {
            color: $white-color;
          }

          >.hotel__building__icon {
            color: $white-color;
          }
        }
      }

      .message__icon__wrapper {
        color: $dark-bright-gray;
        font-size: 20px;
        padding: 0 1rem;
      }

      .notification__wrapper {
        width: 40px;
        height: 40px;
        @include center-div-flex();
        border-radius: $border-radius50per;
        background-color: $notification-color;
        margin: 0rem 0.4rem;

        .notification--icon {
          color: $dark-green;
          font-size: 1.4rem;
          font-weight: $font-weight800;
        }
      }

      .property__dropdown__wrapper {
        @include primary-btn($primary-btn-color,
          $white-color,
          $moderate-blue,
          $white-color);
        justify-content: space-between;
        min-width: 136px;
        padding: 0.44rem 1rem;
        margin: 0rem 0.4rem;
        background-color: $white-color;
        border: 1px solid $moderate-blue;
        border-radius: $border-radius99px;
        transition: background-color 300ms;
        cursor: pointer;

        .hotel--name {
          font-size: 1rem;
          font-weight: $font-weight600;
          color: $moderate-blue;
          @include center-div-flex();

          .hotel__name--dropdown {
            margin-left: 0.6rem;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $moderate-blue;
          background-color: $white-color;
          border-radius: $border-radius99px;

          >.hotel--name {
            color: $white-color;
          }
        }

        &::before {
          border-radius: $border-radius99px;
        }
      }
    }

    .dropdown__menu__manage__wrapper {
      position: absolute;
      inset: 0px 0px auto auto;
      margin: 0px;

      .dropdown__menu_list {
        min-width: 300px;
        background-color: $white-color;
        color: $dark-black;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1),
          0px 16px 32px 2px rgba(97, 97, 97, 0.1);
        border-radius: $border-radius12px;
        overflow: hidden;
        border: none;
        transition: 0.3s ease-in all;

        .mange__user__details {
          padding: 0.85rem 0.85rem 0.85rem 1.6rem;
          background-color: $manage-drop-down-hotel-name;

          .manager__name__label {
            .manage__text {
              @include normal-text();

              .day--status--message {
                font-weight: $very-soft-blue;
              }
            }

            .user__role {
              @include sub-text();
              font-weight: $font-weight700;
              margin-top: 0.5rem;
              color: $moderate-blue;

              .id--number {
                color: $dark-black;
                font-weight: $font-weight400;
              }
            }
          }
        }

        .hotel__manage__section {
          padding-bottom: 0rem;

          .manage__wrapper__items {
            @include center-div-flex();
            justify-content: flex-start;
            flex-grow: 1;
            position: relative;
            color: $dark-gray;
            text-align: left;
            -webkit-tap-highlight-color: transparent;
            outline: 0px;
            cursor: pointer;
            user-select: none;
            vertical-align: middle;
            appearance: none;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: $border-radius8px;
            padding: 0.4rem 0.8rem;
            margin: 0.5rem 1rem;

            .manage__wrapper__items--icon {
              flex-shrink: 0;
              display: inline-flex;
              min-width: 36px;
              color: $moderate-blue;
            }

            .manage__wrapper__items--label {
              flex: 1 1 auto;
              min-width: 0px;
              margin-top: 4px;
              margin-bottom: 4px;
              @include normal-text();
              font-weight: $font-weight400;
              color: $dark-black;

              .subscription__details__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .subscription__label__left {
                  @include normal-text();
                  font-weight: $font-weight400;
                  color: $dark-black;
                }

                .subscription__label__right {
                  @include small-text();
                  color: $moderate-blue;
                  font-weight: $font-weight500;
                }
              }
            }
          }

          .hotel__manage__hover {
            background-color: transparent;

            &:hover {
              background-color: $manage-drop-down-hotel-name;
            }
          }
        }

        .property-list-handle-height {
          max-height: calc(100vh - 360px);
          transition: 0.3s ease-in all;

          .switch__property__wrapper {
            @include center-div-flex();
            justify-content: space-between;
            padding: 0rem 1rem 0rem;
            cursor: pointer;

            .switch__property--icon {
              @include center-div-flex();
              width: 32px;
              height: 32px;
              border-radius: $border-radius50per;
              background-color: $strong-lime-green;
              box-shadow: 0px 2px 4px rgba(31, 139, 36, 0.18),
                0px 4px 8px rgba(31, 139, 36, 0.18);
              margin-right: 0.6rem;

              .switch__property__icon--color {
                color: $white-color;
              }
            }

            .switch__property--label {
              @include normal-text();
              padding-left: 0.6rem;
              color: $dark-black;
            }

            .switch__toggle__button {
              @include center-div-flex();
              width: 32px;
              height: 32px;
              margin-left: auto;
              background-color: rgba(197, 220, 250, 0.5);
              border-radius: $border-radius50per;
              font-size: 0.875rem;

              .toggle__switch__icon--color {
                color: $moderate-blue;
              }
            }
          }

          .property-overflow {
            border-radius: $border-radius12px;
            background-color: rgba(229, 229, 229, 0.5);
            margin: 0.5rem 1rem;
            // margin-right: 1rem;
            overflow: auto;

            // max-height: 220px;
            .property__wrapper {
              @include center-div-flex();
              justify-content: flex-start;
              padding: 0.5rem 0.5rem;
              cursor: pointer;

              .property__name__icon {
                @include center-div-flex();
                width: 36px;
                height: 36px;
                border-radius: $border-radius8px;
                background-color: $very-soft-blue;
                color: $white-color;
                margin-right: 1rem;
              }

              .property__name__wrapper {
                .property__name {
                  @include normal-text();
                  font-weight: $font-weight500;
                }

                .property__address {
                  @include sub-text();
                  font-weight: $font-weight400;
                }
              }
            }
          }

          .add__new__property {
            @include center-div-flex();
            justify-content: space-between;
            cursor: pointer;
            padding: 0 0 0.4rem 0.6rem;
            margin: 0.5rem 1rem;
            color: $dark-black;
            font-weight: 400;
            font-size: 1rem;

            .add--button--icon {
              @include center-div-flex();
              width: 32px;
              height: 32px;
              margin-left: auto;
              background-color: rgba(197, 220, 250, 0.5);
              border-radius: 50%;
              color: $moderate-blue;

              .bi-plus-lg {
                font-size: 1rem;
              }
            }
          }

          .property-overflow__show {
            max-height: 100vh;
            transition: max-height 0.5s ease-in;
          }

          .property-overflow__hide {
            max-height: 0vh;
            transition: 0.5s;
          }

          // .hotel__logout {
          //   border-radius: $border-radius8px;
          //   cursor: pointer;
          //   padding: 0.4rem 0 0.4rem 0.6rem;
          //   margin: 0.5rem 1rem;

          //   .logout--label {
          //     display: flex;
          //     align-items: center;
          //     justify-content: space-between;

          //     .logout--name {
          //       font-weight: $font-weight700;
          //       @include normal-text();
          //       color: $soft-red;
          //       padding: 0.2rem;
          //     }

          //     .logout__icon {
          //       color: $soft-red;
          //       font-size: 0.875rem;
          //       border-radius: $border-radius50per;

          //       // margin-left: auto;
          //       .logout-icon-size {
          //         font-size: 1rem;
          //         padding-right: 0.5rem;
          //       }
          //     }
          //   }
          // }


        }
      }

      &.dropDropMenuShow {
        max-height: 100%;
        opacity: 1;
        pointer-events: all;
        z-index: 10;
        transform: translate(-10px, 58px);
        // transition: transform 0.3s ease-in;
        transition: opacity 385ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.dropDropMenuHide {
        opacity: 0;
        pointer-events: none;
        transform: translate(-10px, 48px) scale(0);
        max-height: 0%;

        transition: opacity 385ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    .dropdown__menu__manage__wrapper__hide {
      @extend .dropdown__menu__manage__wrapper;
      display: none;
    }
  }
}

.header-bg-color {
  color: $white-color;
  background-color: $white-color;
}

.header-position {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
}