.input__password_textfield {
  background-color: white;

  &:focus {
    background-color: white;
  }
}

.disable-cursor {
  cursor: not-allowed !important;
}

// .css-q8hpuo-MuiFormControl-root {
//   .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
//     .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//       padding: 0px 140px;
//     }
//   }
// }