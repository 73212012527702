// left side progress wrapper
.screen__progress__wrapper {
  position: relative;
  top: 7.813rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 85%;
  margin-left: 2.5rem;

  .progress__status__wrapper {
    display: flex;
    align-items: center;

    .status__icon__wrapper {
      @include center-div-flex();
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      border-radius: $border-radius50per;
      border: 1px solid $white-color;

      .progress--icon--check {
        color: $moderate-blue;
        font-size: 2rem;
      }

      .progress--icon--play {
        color: $white-color;
        font-size: 1.65rem;
      }
    }

    .status__label {
      @include extra-bold();
      color: $white-color;
      font-weight: $font-weight600;
      letter-spacing: 1px;
    }
  }

  .progress__sub__list__wrapper {
    margin-left: 3.3rem;

    .progress__sub__listitems {
      color: $light-soft-blue;
      font-weight: $font-weight300;
      padding: 0.23rem 0rem;
      letter-spacing: 1px;
    }

    .progress--sub--item--text {
      color: $white-color;
      font-weight: $font-weight500;
    }
  }
}

.right__log__out__wrap {
  display: flex;
  position: absolute;
  margin-left: auto;
  top: 1rem;
  right: 2rem;

  .logout__btn {
    @include normal-text();
    font-size: $font-size90;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    background-color: $white-text;
    color: $moderate-blue;
    border: 1px solid $lighter-gray;

    &::before {
      background-color: $white-color;
      color: $moderate-blue;
    }
  }

  .property__name__text__wrapper {
    position: relative;
    right: 8rem;
    @include normal-text();
    font-size: $font-size90;
    color: $moderate-blue;
    padding: 0.3rem 0.9rem;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;
    background-color: $white-color;
  }

  .username__name__text__wrapper {
    position: relative;
    right: 8rem;
    @include normal-text();
    font-size: $font-size90;
    color: $moderate-blue;
    padding: 0.3rem 0.9rem;
  }
}

.hotel__icon {
  font-size: 1rem;
}

.congratulations__message__wrapper {
  max-width: 240px;
  text-align: center;
  margin-bottom: 6rem;
  z-index: 10;

  .lottie__wrapper {
    width: 200px;
    margin: 0 auto;
  }

  .congratulation--text {
    @include main-text();
    color: $moderate-blue;
  }

  .congratulation--sub--text {
    @include normal-text();
    color: $bold-gray;
    font-weight: $font-weight400;
    margin: 0.5rem 0rem;
  }
}

.confetti__wrapper {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0%;
  z-index: 1;
}