.cancelation__policy__wrapper {
    background-color: white;
    // padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    // border: 1px solid $border-line;

    .manage__terms__poliy__wrapper {
        // margin-top: 2rem;
        border-radius: $border-radius8px;

        .ck-editor__top {
            border: none;
        }

        .ck-editor__editable {
            min-height: 375px;
            max-height: 375px;
            background-color: $very-light-gray;
            border-radius: $border-radius8px;
            overflow-y: scroll;


            ul>li {
                margin: 0rem 1rem;
                padding: 0;
                text-indent: 0;
                list-style-type: disc;
            }
        }

        .ck-focused {
            border: 1px solid #c4c4c4 !important;
            box-shadow: none !important;
        }
    }

    .character__count__number {
        margin-top: 10px;

        .character__count__label {
            @include normal-text();

            .count_number {
                color: $moderate-blue;
            }
        }
    }

    .save-btn {
        width: 15%;
    }
}