// Checkout screen styles

.slider__content__wrapper {
  margin: 1.1rem;

  .checkout__details__wrapper {
    padding: 1rem;
    background-color: $slider-header-bg;
    border-radius: $border-radius8px;
    border: 1px solid $moderate-blue;

    .checkout__details__label {
      display: flex;
      justify-content: space-between;

      .checkout__guest__name {
        @include main-text();
        font-weight: $font-weight600;
      }

      .booking__channel__icon {
        width: 50px;
        height: auto;

        .booking__source__image {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    .checkout__date__label {
      @include sub-text();
      font-weight: $font-weight500;
      margin: 0.5rem 0;
    }

    .guest__email__contact__details {
      @include center-div-flex();
      justify-content: flex-start;
      color: $moderate-blue;
      font-weight: $font-weight500;

      .guest__email__id {
        margin-left: 0.5rem;
      }
    }

    .room__number__label {
      padding: 0.5rem 1rem;
      color: $white;
      width: max-content;
      border: 1px solid $moderate-blue;
      border-radius: $border-radius8px;
      background-color: $moderate-blue;
    }
  }

  .checkout__inputs__wrapper {
    display: flex;
    gap: 5rem;
  }

  .gray--label {
    color: $dark-bright-gray;
  }

  .button__group__wrapper {
    display: flex;
    flex-wrap: wrap;

    .half--button {
      width: 47%;
      margin: 0.5rem 0.5rem 0.5rem 0;
    }

    .full--button {
      width: 95.5%;
    }
  }
}

.checkout__room__wrapper {
  display: flex;
  align-items: flex-start;

  .checkout__room__left__wrapper {
    width: 45.6333%;
    flex-basis: 45.6333%;
    flex-grow: 0;
    max-width: 45.6333%;

    .check__in__out__date {
      padding: 1rem;
      background-color: $slider-header-bg;
      border-radius: $border-radius8px;
      border: 1px solid $moderate-blue;

      .checkin__checkout__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkinout__content {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;

          .check__inout__details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            background-color: $white-color;
            border-radius: $border-radius8px;

            .check__inout__icon {
              padding: 0.5rem;

              i {
                font-size: 1.688rem;
              }
            }

            .check-in-color {
              // color: $lime-green;
              color: $dark-black;
            }

            .check-out-color {
              // color: $smooth-red;
              color: $dark-black;
            }

            .check__inout__date {
              padding: 0rem 0.5rem;

              .check-in-color {
                // color: $lime-green;
                color: $dark-black;
                font-weight: $font-weight600;
              }

              .check-out-color {
                // color: $smooth-red;
                color: $dark-black;
                font-weight: $font-weight600;
              }

              .check__inout__label {
                @include sub-text();
                color: $table-month-text;
                font-weight: $font-weight400;
              }
            }
          }

          .check-in-border {
            border: 1px solid $dark-black;
          }

          .check-out-border {
            border: 1px solid $dark-black;
          }
        }

        .number__of__nights__wrapper {
          display: inline-flex;
          align-items: center;
          color: $moderate-blue;

          .night__icon {
            margin-right: 0.5rem;
          }

          .night__number {
            font-size: 1.445rem;
          }
        }
      }
    }

    .guest__details__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navigation__arrow {
        display: flex;
        align-items: center;

        .left__arrow,
        .right__arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: $soft-gray;
          border-radius: $border-radius50per;

          i {
            font-weight: $font-weight600;
          }

          &.active__nav {
            background-color: $very-soft-blue;
          }
        }
      }
    }

    .guest__details__card {
      padding: 1rem;
      background-color: $slider-header-bg;
      border: 1px solid $moderate-blue;
      border-radius: $border-radius8px;

      .guest__name {
        @include main-text();
        font-weight: $font-weight600;
      }

      .guest__email__contact__details,
      .guest__gender__age__wrapper {
        @include center-div-flex();
        justify-content: flex-start;
        color: $moderate-blue;
        font-weight: $font-weight500;

        .guest__email__id,
        .age {
          margin-left: 0.5rem;
        }
      }

      .guest__document__and__image__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .upload__document,
        .upload__image {
          @include center-div-flex();
          @include normal-text();
          width: 100%;
          font-weight: $font-weight500;
          color: $moderate-blue;
          padding: 0.5rem 1rem;
          background-color: $white-color;
          border: 1px solid $moderate-blue;
          border-radius: $border-radius8px;
        }
      }
    }

    .allocated__guest__room__wrapper {
      border: 1px solid $border-line;
      border-radius: $border-radius8px;

      .guest__room__image {
        position: relative;
        max-width: 100%;
        height: 200px;
        border-radius: $border-radius8px $border-radius8px 0 0 !important;

        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius8px $border-radius8px 0 0 !important;
        }

        .room__number {
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: $white-color;
          font-weight: $font-weight600;
          padding: 0.5rem 1rem;
          background-color: $lime-green;
          border-radius: $border-radius8px;
        }
      }

      .room__detail__wrapper {
        padding: 1rem;
        background-color: $white-color;
        border-radius: 0 0 $border-radius8px $border-radius8px;

        .room__name__with__meal__plan {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .room__name {
            @include normal-text();
            font-weight: $font-weight600;
          }

          .mela__plan__name {
            display: flex;
            align-items: center;
            width: fit-content;
            color: $white-text;
            padding: 0.34rem;
            background-color: $moderate-blue;
            border-radius: $border-radius8px;
          }
        }

        .room__price {
          @include normal-text();
          font-weight: $font-weight600;
        }
      }
    }
  }

  .checkout__room__right__wrapper {
    width: 54.3667%;
    flex-basis: 54.3667%;
    flex-grow: 0;
    max-width: 54.3667%;

    .housekepping__clearness__wrapper,
    .checkout__billing__clearance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.23rem 1rem;
      background-color: $white-color;
      border-radius: $border-radius8px;
      border: 1px solid $border-line;

      .housekeeping__label,
      .billing__clearance__label {
        @include normal-text();

        .billing__clearance__due__amount {
          background-color: $pale-red;
          color: $strong-red;
          font-size: $font-size90;
          font-weight: $font-weight700;
          padding: 0.235rem 0.432rem;
          // border-radius: $border-radius26px;
        }
      }

      .housekepping__checkbox,
      .billing__clearance__checkbox {
        .form-check {
          input {
            &[type="checkbox"] {
              width: 23px;
              height: 23px;
            }
          }
        }
      }
    }

    .checkout__btn__wrapper {
      .primary-btn {
        @include primary-btn($white-color,
          $smooth-red,
          $strong-red,
          $white-color );
        width: 100%;
      }
    }
  }
}

.bill__clearance__bill__slider__wrapper {
  padding: 1rem;
  margin: 1rem;
  margin-top: 0rem;

  .choose__new__checkin__checkout__wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0.658rem;
    margin-bottom: 2.6rem;
    border: 2px solid $moderate-blue;
    border-radius: $border-radius8px;
    cursor: pointer;

    .date__range__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: $moderate-blue;
    }

    .date__range__label {
      @include normal-text();
      font-weight: $font-weight400;
    }
  }

  .due__bill__collection__amount__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .due__bill__colleection__amount {
      width: 100%;
    }
  }
}