// style for checkin pages
.check__in__booking__id {
  @include normal-text();
  color: $moderate-blue;
}

.bookings__guest__check__in__wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;

  .guest__booking__details__stepper__left {
    width: 50.3667%;
    flex-basis: 50.3667%;
    flex-grow: 0;
    max-width: 50.3667%;
    background-color: $white-color;
    border-radius: $border-radius8px;

    .standard__card__wrapper {
      width: 100%;
      border: 1px solid $border-line;
      border-radius: $border-radius8px;
      background-color: $white-color;
      padding: 1rem;
      border-radius: $border-radius8px;

      .guest__billing__tab__sections {
        .booking__room__detail__wrapper {
          display: flex;
          align-items: center;
          border-radius: $border-radius8px;
          border: 1px solid $border-line;

          .booking__room__image {
            display: flex;
            flex-direction: column;
            width: 200px;
            height: 183px;

            img {
              display: flex;
              flex: 1 0 auto;
              min-width: 200px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: $border-radius8px 0 0 $border-radius8px;
            }
          }

          .booking__room__details {
            width: calc(100% - 200px);
            padding: 0.5rem 1rem;

            .room__type__meal__plan {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .room__type__name {
                display: flex;
                align-items: center;
                @include normal-text();
                font-weight: $font-weight500;

                i {
                  font-size: 1.2rem;
                  color: $moderate-blue;
                }
              }

              .room__type__meal__plan {
                width: fit-content;
                color: $white-color;
                padding: 0.3rem 0.65rem;
                background-color: $moderate-blue;
                border-radius: $border-radius8px;
              }
            }

            .booking__room__type__rate,
            .booking__room__nights,
            .booking__room__type__total__amount {
              display: flex;
              align-items: center;
              @include normal-text();
              font-weight: $font-weight500;

              i {
                font-size: 1.2rem;
                color: $moderate-blue;
              }
            }

            .booking__room__type__total__amount {
              width: fit-content;
              color: $white-color;
              font-weight: $font-weight400;
              text-align: center;
              padding: 0.3rem 0.65rem;
              background-color: $moderate-blue;
              border-radius: $border-radius8px;
            }
          }
        }

        .best__price__wrapper,
        .final__price__breakup {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .best__price,
          .total__label,
          .total__price {
            font-weight: $font-weight600;
          }
        }

        .price__collection__wrapper {
          display: flex;
          align-items: center;

          .form-check-input[type="checkbox"] {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }

      .guest__info__tab__sections {
        .room__number__wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .room__wrapper {
            width: 100%;
            max-width: 250px;
            padding: 1rem;
            border-radius: $border-radius8px;
            box-shadow: $container-box-shadow;

            .room__number__meal__plan__wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .room__number {
                @include normal-text();
                font-weight: $font-weight600;
              }

              .room__meal__plan {
                width: fit-content;
                color: $white-color;
                padding: 0.3rem 0.65rem;
                background-color: $moderate-blue;
                border-radius: $border-radius8px;
              }
            }

            .add__room__guest__wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .add__room,
              .add__guest {
                width: 100%;

                .primary-btn {
                  min-width: unset;
                  padding: 0.5rem;
                }
              }

              .assigned__room__guest {
                display: flex;

                .primary-btn {
                  @include primary-btn($white-color,
                    $lime-green,
                    $dark-green,
                    $white-color );
                  width: 100%;
                  min-width: unset;
                  padding: 0.5rem;
                }

                .blue-button {
                  @include primary-btn($primary-btn-color,
                    $white-color,
                    $moderate-blue,
                    $white-color );
                  background-color: $primary-btn-color;
                  color: $white-color;
                  width: 100%;
                  min-width: unset;
                  padding: 0.5rem;
                }
              }
            }
          }
        }
      }

      .check__in__next__btn {
        display: flex;
      }
    }
  }

  .guest__booking__info__stepper__right {
    width: 49.6333%;
    flex-basis: 49.6333%;
    flex-grow: 0;
    max-width: 49.6333%;
    height: fit-content;

    .guest__booking__info__card {
      padding: 1rem;
      background-color: $slider-header-bg;
      border: 1px solid $moderate-blue;
      border-radius: $border-radius8px;

      .guest__name__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .guest__name {
          @include main-text();
          font-weight: $font-weight600;
        }

        .booking__channel__icon {
          width: 50px;
          height: auto;

          .booking__source__image {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }

      .guest__booking__date {
        @include sub-text();
        font-weight: $font-weight500;
      }

      .guest__email__contact__details {
        @include center-div-flex();
        justify-content: flex-start;
        color: $moderate-blue;
        font-weight: $font-weight500;

        .guest__email__id {
          margin-left: 0.5rem;
        }
      }

      .checkin__checkout__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkinout__content {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;

          .check__inout__details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            background-color: $white-color;
            border-radius: $border-radius8px;

            .check__inout__icon {
              padding: 0.5rem;

              i {
                font-size: 1.688rem;
              }
            }

            .check-in-color {
              // color: $lime-green;
              color: $dark-black;
            }

            .check-out-color {
              // color: $smooth-red;
              color: $dark-black;
            }

            .check__inout__date {
              padding: 0rem 0.5rem;

              .check-in-color {
                // color: $lime-green;
                color: $dark-black;
                font-weight: $font-weight600;
              }

              .check-out-color {
                // color: $smooth-red;
                color: $dark-black;
                font-weight: $font-weight600;
              }

              .check__inout__label {
                @include sub-text();
                color: $table-month-text;
                font-weight: $font-weight400;
              }
            }
          }

          .check-in-border {
            border: 1px solid $dark-black;
          }

          .check-out-border {
            border: 1px solid $dark-black;
          }
        }

        .number__of__nights__wrapper {
          display: inline-flex;
          align-items: center;
          color: $moderate-blue;

          .night__icon {
            margin-right: 0.5rem;
          }

          .night__number {
            font-size: 1.445rem;
          }
        }
      }
    }
  }
}

.slider__card__guest__details__wrapper {
  padding: 1rem;
  margin: 1rem;
  margin-top: 0rem;

  .slider__guest__details {
    padding: 1rem;
    border: 1px solid $border-line;
    border-radius: $border-radius8px;

    .mobile__number__search__wrapper {
      display: flex;
      align-items: flex-start;

      .mobile__number__input {
        width: 100%;
      }

      .mobile__search__input {
        cursor: pointer;
        color: $white-color;
        padding: 0.758rem 1rem;
        background-color: $moderate-blue;
        border-radius: $border-radius8px;
      }
    }

    .guest__email__age__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .guest__email__address__input {
        width: 80%;
      }

      .guest__age__input {
        width: 20%;
      }
    }

    .guest__document__and__image__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .upload__document,
      .upload__image {
        @include center-div-flex();
        @include normal-text();
        width: 100%;
        font-weight: $font-weight500;
        color: $moderate-blue;
        padding: 0.5rem 1rem;
        border: 1px solid $moderate-blue;
        border-radius: $border-radius8px;
      }
    }
  }
}

.select__roon__floor__wrapper {
  padding: 1rem;
  margin: 1rem;
  margin-top: 0rem;

  .floor__wise__room__select {
    .floor__number {
      width: fit-content;
      font-weight: $font-weight500;
      color: $dark-green;
      padding: 0.3rem 1rem;
      border-radius: $border-radius8px;
      background-color: $light-green;
    }

    .floor__number__wrapper {
      display: flex;
      flex-wrap: wrap;

      .react-ripples {
        width: 97px;
        border-radius: $border-radius12px;

        .floor__number__box {
          width: 100px;
          display: flex;
          align-items: center;
          text-align: center;
          border-radius: $border-radius12px;

          .floor__number {
            width: 96px;
            height: 96px;
            padding: 2.2rem 0;
            color: $white-text;
            font-weight: $font-weight700;
            text-align: center;
            border-radius: $border-radius12px;
            background-color: $lime-green;

            &:hover {
              cursor: pointer;
            }
          }

          .floor__number__box__active {
            @extend .floor__number__box;
            border: 22px solid $smooth-red;
          }

          .floor__number__gray {
            @extend .floor__number;
            background-color: $dark-bright-gray;
          }
        }
      }
    }
  }
}

.doc__upload__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .div_image_upload__checkin {
    @extend .div_image_upload !optional;
    min-width: unset;
    height: 204px;

    .checkin__image {
      width: 300px;
      height: 200px;
      cursor: pointer;
      border-radius: $border-radius8px;
    }
  }
}

.room__checkin__doc__upload {
  .calendar__view__tab {
    .nav-tabs {
      width: 100%;
      padding: 0.3rem;
      border-radius: $border-radius8px;
      background-color: $slider-header-bg;
      box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
        inset 0px 2px 4px rgba(97, 97, 97, 0.2);

      .nav-item {
        flex: 1;

        .nav-link {
          @include normal-text();
          color: $moderate-blue;
          font-weight: $font-weight600;
          text-align: center;
          padding: 0.33rem 0.75rem;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: $white-color;
          border-radius: $border-radius8px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }
}