.calendar__filter__wrapper {
  .listview__datepicker__wrapper {
    max-width: 200px;
  }

  .listview__select__date__wrapper {
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 250px;
    height: 52px;
    padding: 0.6rem;
    background-color: $white-color;
    // margin-bottom: 2.6rem;
    border: 1px solid $moderate-blue;
    border-radius: 8px;
    cursor: pointer;
  }

  .date__dropdown__wrapper {
    min-width: 180px;
    background-color: $white-color;
  }

  .DateInput {
    // margin-left: 10px !important;
    width: 100px !important;
  }

  .DateInput_input {
    color: #484848 !important;
  }

  .DateRangePickerInput_arrow_svg {
    display: none;
  }

  .css-2m9kme-MuiFormControl-root {
    margin: 0 !important;
  }

  .status__dropdown__wrapper {
    min-width: 190px;
    background-color: $white-color;

    .MuiFormControl-root,
    .css-1mzc8 {
      margin: 0 !important;
      min-width: 180px;
      max-width: 190px;
    }
  }

  .payment_optn {
    .MuiFormControl-root {
      margin: 0 !important;
      min-width: 245px;
      max-width: 245px;
    }
  }

  .custom--width {
    width: 180px;
    max-width: 180px;

    .MuiFormControl-root {
      // max-width: 180px;
    }
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 12px 14px 12px !important;
  }

  .dropdown__right__wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;

    // .css-2m9kme-MuiFormControl-root {
    //   min-width: 140px !important;
    //   width: 0px;
    // }
  }

  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 14px 14px;
  }

  .css-1z7n62 {
    span {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 300px !important;
}

.list-view-table-wrapper {
  cursor: default;
  width: 100%;
  height: 100px;

  .bi-caret-down-fill,
  .bi-caret-up-fill {
    font-size: 14px;
  }

  .table {
    cursor: default;
    background-color: white;

    border-radius: $border-radius12px;

    .booking--loader {
      @include center-div-flex();
      min-height: 400px;
    }

    .listview__table__heading {
      background-color: $bg-color;
      color: $lighter-gray;

      th {
        font-weight: $font-weight600 !important;
        text-align: left;
        vertical-align: top;

        .poniter {
          cursor: pointer;
        }
      }
    }

    .listview__table__data {
      background-color: $white-color;
      border-top-left-radius: $border-radius8px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      td {
        vertical-align: middle;
        text-align: left;

        .booking__id {
          color: $moderate-blue;
          font-weight: $font-weight500;
          text-decoration: underline;
          cursor: pointer;

          .business__booking__wrapper {
            @include center-div-flex();
            display: inline-flex;
            width: 26px;
            height: 26px;
            background-color: $moderate-blue;
            border-radius: $border-radius50per;

            i {
              font-size: 0.75rem;
              color: $white-color;
            }
          }
        }

        .listview__image {
          width: 100px;
          height: 35px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .reports-guest-name {
          width: auto;
          height: unset;
        }

        .booking__date {
          padding: 0.5rem 1rem;
          font-weight: $font-weight500;
        }

        .guest {
          max-width: 200px;
          font-weight: $font-weight500;
        }

        .check__in__out {
          color: $moderate-blue;
          font-weight: $font-weight500;
        }

        .confirmed__label {
          max-width: 190px !important;
          display: flex;
          margin: 0 auto;
          margin-right: 0 !important;
          padding: 0.3rem 1rem;
          text-align: center;
          // color: $dark-green;
          color: $strong-lime-green;
          // border-radius: $border-radius8px;
          // background-color: $very-soft-blue;

          // &:hover {
          //   cursor: pointer;
          // }
        }

        .cancelled {
          max-width: 190px !important;
          margin-right: 0 !important;
          margin: 0 auto;
          padding: 0.4rem 1rem;
          text-align: left;
          // color: $strong-red;
          color: $smooth-red;
          // border-radius: $border-radius8px;
          // background-color: $strong-red;

          // &:hover {
          //   cursor: pointer;
          // }
        }

        .payment_status_text {
          color: #494949;
          width: 400;
          size: 14px;
        }

        .payment_status__label {
          max-width: 160px !important;
          padding: 0.3rem 1rem;
          text-align: center;
          // color: $dark-green;
          // color: $strong-lime-green;

          // font-weight: $font-weight500;
          // border-radius: $border-radius12px;
          // background-color: $light-green;
        }

        .pay__at__hotel {
          max-width: 160px !important;
          padding: 0.3rem 1rem;
          color: $strong-red;
          text-align: left;
          // font-weight: $font-weight500;
          // border-radius: $border-radius12px;
          // background-color: $pale-red;
        }

        .partialy__paid {
          max-width: 160px !important;
          padding: 0.3rem 1rem;
          // color: $soft-orange;
          color: $popular-app-color-orange;
          text-align: left;
          // font-weight: $font-weight500;
          // border-radius: $border-radius12px;
          // background-color: $booked_inventory;
        }
      }

      /* Style for drop down menu container */
      .drop-down-container {
        position: relative;
        text-align: center;
      }

      .dropdown-header {
        cursor: pointer;
      }

      .dropdown-list {
        position: absolute;
        left: -50px;
        padding: 5px;
        background-color: $white-color;
        border-radius: $border-radius12px;
        z-index: 5;
        display: flex;
        flex-direction: column;
        box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.35);

        .option {
          padding: 5px;
          @include sub-text();
        }

        .option-disabled {
          padding: 5px;
          @include sub-text();
          color: #bebebe;
          cursor: no-drop;
        }
      }
    }
  }

  .table-borderless> :not(caption)>*>* {
    border-bottom: 8px solid $bg-color;
  }
}

.list--view--pagination {
  display: flex;
  padding-bottom: 2rem;

  .pagination__left {
    display: flex;
    justify-content: flex-start;

    .pagination__dropdown {
      min-width: 70px;
      background-color: $white-color;
    }

    ul {
      display: flex;

      .showing__page__number {
        @include center-div-flex();
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin-left: 0.9rem;
      }
    }

    .active__page {
      @extend .showing__page__number;
      color: $white-color;
      padding: 0 12px;
      font-weight: $font-weight600;
      border-radius: $border-radius50per;
      background-color: $moderate-blue;
    }

    .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 14px 14px;
    }

    .css-1z7n62 {
      span {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .pagination__right {
    display: flex;
    margin-left: auto;

    .arrow__container {
      @include center-div-flex();
      width: 36px;
      height: 36px;
      padding: 6px 8px;
      color: $moderate-blue;
      background-color: $soft-gray;
      border-radius: $border-radius50per;
      cursor: pointer;

      i {
        -webkit-text-stroke: 0.5px;
      }
    }

    .font--weight {
      font-weight: $font-weight700;
    }
  }

  .MuiSelect-select {
    padding: 8px 14px !important;
  }
}

//Date picker override
.MuiOutlinedInput-notchedOutline {
  border-color: $moderate-blue !important;
  // new added can be modified
  // padding: 0px 140px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: inherit !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  background-color: $white-color !important;
}

.listview__table__heading {
  vertical-align: top;
}

.prepaid__label {
  max-width: 160px !important;
  padding: 0.3rem 1rem;
  color: $lime-green;
  text-align: left;
  font-weight: $font-weight500;
}

.scrollbar-thin {
  scrollbar-width: thin !important;
}

.scrollbar-thin {
  &::-webkit-scrollbar {
    // width: 8px; // for vertical scroll bar
    height: 8px; // for horizontal scroll bar
  }
}

.list-view-table-wrapper {
  .table {
    .reports__table__data {
      td {
        min-width: 210px;
      }
    }
  }
}