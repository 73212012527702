.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: $manrope-font !important;
  line-height: 1.137em !important;
}

.css-q8hpuo-MuiFormControl-root {
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    margin-top: -0.1875rem !important;
  }
}

// for input feild
.css-q8hpuo-MuiFormControl-root {

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root,
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 13px 14px !important;
  }
}

// for select feild
.MuiOutlinedInput-root {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $moderate-blue !important;
  // new added can be modified
  // padding: 0px 140px;
}

.MuiFormLabel-root.Mui-focused {
  color: $moderate-blue !important;
}

.inputfieldstyle {
  min-width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.Mui-disabled {
  cursor: no-drop !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  cursor: no-drop !important;
}

.MuiFormControl-root {
  width: 100%;

  .MuiOutlinedInput-input {
    padding: 14px 14px !important;
    background-color: $white-color !important;
  }

  .MuiSelect-select {
    padding: 14px 14px !important;
    background-color: $white-color !important;
  }
}

.inventory__room__type__select {

  .css-q8hpuo-MuiFormControl-root,
  .MuiSelect-select {
    min-width: 250px;
    background-color: $white-color;
    border-radius: $border-radius8px;
  }
}

.status__dropdown__wrapper {
  .MuiFormControl-root {
    margin: 0 !important;
  }

  .css-1mzc8 {
    margin: 0 !important;
  }
}

// unset the legend width of mui feilds to view the top border
.mui__input__fieldset__legends__unset {
  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      fieldset {
        legend {
          width: unset;
        }
      }
    }
  }
}

// rest the padding of mui input feild
.mui__datepicker__input__padding__reset {
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 10.5px !important;
      }
    }
  }
}