* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// override default selection color
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $lighter-gray;
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px $lighter-gray;
  background-color: $lighter-gray;
}

:focus-visible {
  outline: none;
}

html {
  font-size: 16px;
}

html,
body,
.app {
  font-family: $manrope-font;
  height: 100%;
  background-color: $bg-color;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

a {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
  text-decoration: none;
}

ul,
ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

// reset bootstrap input style
textarea:hover,
input:hover,
textarea:active,
input:active,
select,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.height-75px {
  min-height: 75px;
  height: 75px;
}

.hr--line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(224, 224, 224, 100%);
  margin: 1em 0;
  padding: 0;
}

.rateDropShow {
  // margin-bottom: 1rem;
  max-height: 100vh;
  transition: max-height 0.5s ease-in;
}

.dropShow {
  margin-bottom: 1rem;
  max-height: 30vh;
  transition: max-height 0.5s ease-in;
}

.dropHide {
  max-height: 0vh;
  transition: 0.5s;
}

.standard--margin--top {
  margin-top: 1.5rem;
}

.support__help__text {
  position: absolute;
  bottom: 3rem;
  padding: 0.2rem 0.6rem;
  background-color: $white-color;
  border-radius: $border-radius50px;

  .get__help__link {
    color: $moderate-blue;
    font-weight: $font-weight600;
  }
}

// CSS reset for forms
input {
  @extend %form-reset-input;
}

label {
  @extend %form-reset-label;
}

select {
  @extend %form-reset-select;
}

button {
  @extend %form-reset-button;
}

textarea {
  @extend %form-reset-textarea;
}

// hide the scrollbar
.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow: -moz-scrollbars-none;
  /* Old Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

// fix the issue contenteditable
[contenteditable] {
  -webkit-user-select: text !important;
  user-select: text !important;
}

// loader style
.loader__line__container {
  padding: 1rem;
  width: 100%;

  .content__line__loader {
    &.skeleton {
      // NOTICE THIS
      width: 100%;
      min-height: 1rem;
      border-radius: $border-radius2px;
    }
  }

  // THE LOADING EFFECT
  .skeleton {
    background-color: #e2e5e7;
    // The shine that's going to move across the skeleton:
    background-image: linear-gradient(90deg,
        rgba(#fff, 0),
        rgba(#fff, 0.5),
        rgba(#fff, 0));
    background-size: 40px 100%; // width of the shine
    background-repeat: no-repeat; // No need to repeat the shine effect
    background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
    animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
  }
}

// loader shine animation
@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

.full--page--overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // z-index: 9999;
  // pointer-events: none;
  // backdrop-filter: blur(0.7px);
  // background-color: rgba(0, 0, 0, 0.1);
}

// Default select tab button
.select__input__label {
  display: flex;
  flex-wrap: wrap;

  .select__input__div__active,
  .select__input__div {
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: 2px solid $border-line;
    border-radius: 16px;
    transition: all 0.15s ease-in-out;
  }

  .select__input__div__active {
    border-color: $soft-blue;
    color: $moderate-blue;
    font-weight: $font-weight700;
  }
}

.unclickable {
  pointer-events: none;
}

.rotate-180 {
  transform: scale(1, -1);
}

// section standard margin right
.sm-r {
  margin-right: 2rem;
}

.loader-setup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.form-check-input {
  cursor: pointer;
}